import React, { useState, useCallback, useEffect, Fragment } from "react";

import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/js/src/collapse.js";
import styled from 'styled-components';
import { Container, Row, Col, Image, Accordion } from 'react-bootstrap';

import NavigationBar from './NavigationBar';
//import Header from './Header';
import Footer from './Footer';
import { useTranslation } from "react-i18next";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};
const Styles = styled.div`
.bg-SunPowerBlue {
	background-color: #0076be;
}
.payment-radio {
    margin-left: 150px;
}
.form-check-label {
    text-align: center important!;
}
.ant-radio-button {
    padding: 10px;
}
.OrangeBtn {
    margin-left: 400px;
	font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn:hover {
    
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2 {
    font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2:hover {
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.BlueBtn {
	font-weight: 500;
	background-color: #0076BE;
	color: #FFFFFF;
}

.BlueBtn:hover {
	background-color:#39607A;
	color: #FFFFFF;
}

.BlueBtn:active {
	background-color:#39607A;
	color: #FFFFFF;
}
.BlueBtn:focus {
	background-color:#39607A;
	color: #FFFFFF;
}


.BlueBtnOutline {
	font-weight: 500;
	border: 1px solid #0076BE;
    background-color:#FFFFFF !important;
    color: #0076BE;
}

.BlueBtnOutline:hover {
	background-color:#0076BE !important;
	color: #FFFFFF;
}

.BlueBtnOutline:focus {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueBtnOutline:active {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueRadioBtn:hover {
	border: 1px solid #0076BE;
	color: #0076BE;
}

.BlueRadioBtn.active {
	border: 1px solid #0076BE;
	color: #0076BE;
}

footer{
	height: 80px;
	
	width: 100%;
}
.errorMsg {
    color: red;
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.divider-light {
	background-color: #E0E0E5;
	height: 2px;
	margin-bottom: 14px;
}

.divider-orange {
	background-color: #F18B21;
	height: 3.5px;
	width: 36px;
}

.divider-orange-lg {
	background-color: #F18B21;
	height: 3px;
	width: 100px;
}


.bg-banner1 {
	background-image: url("images/1200x627_banner no logo (1).png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
}

.bg-SunPowerBlack {
	background-color: hsla(0,0%,0%,0.60);
	color:#FFFFFF;
	text-align: center;
}



/*Nav*/
.nav-link.active {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}

.nav-link:hover {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}
/*Nav*/

/*Tabs*/

.nav-link.tab-title {
	color: #858585;
	background-color: #EEEEEE;
}

.nav-link.tab-title:hover {
	color: #E2620E;
}

.nav-link.tab-title.active {
	color: #E2620E;
}

/*Tabs*/


/*Links*/

.OrangeLink {
	color: #F18B21;
}
.OrangeLink:hover {
	color: #E2620E;
}

.BlueLink {
	color: #0076BE;
}

/*Typography*/

h1, h2, h3, h4, h5 {
	font-weight: 500;
}
`;

const Faqs = (props) => {
    const { t } = useTranslation();
    return (
        <Fragment>

            <NavigationBar/>
           
            <Container>
                <Row className="text-white bg-SunPowerBlue">
                    <Col lg={6} className='px-0'>
                        <Image fluid src="images/Header-FAQ.png" className="h-100 ms-0" />
                    </Col>
                    <Col lg={6} className="p-5 align-self-center">
                        <h2 className="text-white text-uppercase">Frequently Asked Questions</h2>
                    </Col>
                </Row>
                <Row className="mt-3 p-2">
                    <Col>
                        <Styles>
                            <Accordion defaultActiveKey={['0']} alwaysOpen flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{t('cannot_find_tracking_no')}</Accordion.Header>
                                    <Accordion.Body>
                                        {t('cannot_find_tracking_no_ans')} <a href="/contactus" className="OrangeLink">{t('contact_us')}</a> {t('faq_page')}.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>{t('will_i_receive_payment')}</Accordion.Header>
                                    <Accordion.Body>
                                        {/* {t('weeks_to_receive_sunpower_payment')} */}
                                        {t('will_i_recieve_payment_ans')}
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>{t('switch_my_payment_type')}</Accordion.Header>
                                    <Accordion.Body>
                                        {t('faq3_answer')}
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>{t('need_to_change_mail_addr')}</Accordion.Header>
                                    <Accordion.Body>
                                            {t('change_mailing_add_ans')}                                  
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>{t('link_in_email_does_not_work')}</Accordion.Header>
                                    <Accordion.Body>
                                    {t('not_recieved_visa_reward_card_ans')} <a href="/contactus" className="OrangeLink">{t('contact_us')}</a> {t('faq_page')} 
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>{t('does_my_card_expire')}</Accordion.Header>
                                    <Accordion.Body>
                                        {/* {t('faq7_answer')} */}
                                        {t('does_my_card_expire_ans')}
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>{t('not_recieved_visa_reward_card')}</Accordion.Header>
                                    <Accordion.Body>
                                        {t('not_recieved_visa_reward_card_ans')} <a href="/contactus" className="OrangeLink">{t('contact_us')}</a> {t('faq_page')}.
                                    </Accordion.Body>
                                </Accordion.Item>

                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>{t('tracking_referral_payment')}</Accordion.Header>
                                        <Accordion.Body>
                                            {t('status_and_meaning')}:
                                            <ol type="1">
                                                <li>{t('pending_status_mean')}</li>
                                                <li>{t('processing_status_mean')}</li>
                                                <li>{t('shipped_status_mean')}</li>
                                            </ol>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header>{t('waiting_for_payment')}</Accordion.Header>
                                            <Accordion.Body>
                                                {t('waiting_for_payment_ans')}<a href="/contactus" className="OrangeLink">{t('contact_us')}</a> {t('faq_page')}.
                                            </Accordion.Body>
                                        </Accordion.Item>
                            </Accordion>
                        </Styles>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </Fragment>

    );
}

export default Faqs;