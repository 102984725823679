import { Form, Input } from "antd"

const LastName = () => {
	return (
		<Form.Item
			name="lastName"
			label="Last Name"
			rules={[
				{
					required: true,
					whitespace: true,
					message: 'This field is mandatory to complete this request.',
				},
			]}
		>
			<Input />
		</Form.Item>
	)
}

const Email = () => {
	return (
		<Form.Item
			name="email"
			label="Email"
			rules={[
				{ type: 'email', message: 'Please enter a valid email address' },
				{
					required: true,
					message: 'This field is mandatory to complete this request.',
				},
			]}
		>
			<Input />
		</Form.Item>
	)
}

const Phone = ( ) => {
	return (
		<Form.Item
			name="phone"
			label="Phone"
			rules={[
				{
					required: true,
					message: 'This field is mandatory to complete this request.',
				},
				{
					pattern: /^[1-9][0-9]{9}$/,
					message: 'Phone number must be 10 digits ',
				},
			]}
		>
			<Input  />
		</Form.Item>
	)
}

export {LastName,Phone,Email}