import React, { useState, useCallback, useEffect, Fragment } from "react";

import gql from 'graphql-tag';
import 'bootstrap/dist/css/bootstrap.css';
import { Link, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from "@apollo/client";
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Form, Input, Button, Checkbox, InputNumber, Alert } from 'antd';
import { Container, Row, Col, Image } from 'react-bootstrap';
import {patterns} from '../Constants'
import LoadingOverlay from 'react-loading-overlay'
import NavigationBar from './NavigationBar';
import Header from './Header';
import Footer from './Footer';
import { useTranslation } from "react-i18next";
import { type } from "@testing-library/user-event/dist/type";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};
const Styles = styled.div`




.bg-SunPowerBlue {
	background-color: #0076be;
}
.payment-radio {
    margin-left: 150px;
}
.form-check-label {
    text-align: center important!;
}
.ant-radio-button {
    padding: 10px;
}
.OrangeBtn {
    margin-left: 400px;
	font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn:hover {
    
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2 {
    font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2:hover {
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.BlueBtn {
	font-weight: 500;
	background-color: #0076BE;
	color: #FFFFFF;
}

.BlueBtn:hover {
	background-color:#39607A;
	color: #FFFFFF;
}

.BlueBtn:active {
	background-color:#39607A;
	color: #FFFFFF;
}
.BlueBtn:focus {
	background-color:#39607A;
	color: #FFFFFF;
}


.BlueBtnOutline {
	font-weight: 500;
	border: 1px solid #0076BE;
    background-color:#FFFFFF !important;
    color: #0076BE;
}

.BlueBtnOutline:hover {
	background-color:#0076BE !important;
	color: #FFFFFF;
}

.BlueBtnOutline:focus {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueBtnOutline:active {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueRadioBtn:hover {
	border: 1px solid #0076BE;
	color: #0076BE;
}

.BlueRadioBtn.active {
	border: 1px solid #0076BE;
	color: #0076BE;
}


footer{
	height: 80px;
	
	width: 100%;
}
.errorMsg {
    color: red;
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.divider-light {
	background-color: #E0E0E5;
	height: 2px;
	margin-bottom: 14px;
}

.divider-orange {
	background-color: #F18B21;
	height: 3.5px;
	width: 36px;
}

.divider-orange-lg {
	background-color: #F18B21;
	height: 3px;
	width: 100px;
}


.bg-banner1 {
	background-image: url("images/1200x627_banner no logo (1).png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
}

.bg-SunPowerBlack {
	background-color: hsla(0,0%,0%,0.60);
	color:#FFFFFF;
	text-align: center;
}



/*Nav*/
.nav-link.active {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}

.nav-link:hover {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}
/*Nav*/

/*Tabs*/

.nav-link.tab-title {
	color: #858585;
	background-color: #EEEEEE;
}

.nav-link.tab-title:hover {
	color: #E2620E;
}

.nav-link.tab-title.active {
	color: #E2620E;
}

/*Tabs*/


/*Links*/

.OrangeLink {
	color: #F18B21;
}
.OrangeLink:hover {
	color: #E2620E;
}

.BlueLink {
	color: #0076BE;
}

/*Typography*/

h1, h2, h3, h4, h5 {
	font-weight: 500;
}
`;

const GET_STATUS = gql`
query Query($trackingNo: Float!) {
    trackReferralStatus(trackingNo: $trackingNo)
  }
`;



const TrackPayment = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [status, setStatus] = useState();
    const [messaging,setMessage]=useState();
    const [errorRes, setError] = useState();
    const [messageFromRedirect,setMessageFromRedirect]=useState();
    const location = useLocation();
    let sentence1='';
    let sentence2='';
    let restofsentence='';
    const [getPaymentStatus] = useLazyQuery(GET_STATUS, {
        onCompleted(data) {
            [sentence1,sentence2,...restofsentence]=data.trackReferralStatus.split('.')
            console.log(sentence1,sentence2,restofsentence);
            if(sentence2!=undefined){
                setStatus(`${sentence1}<br/>${sentence2}`);
            }
            else{
                setStatus(`${sentence1}`);

            }
            setMessage(`${restofsentence}`);
        },
        onError(err) {
            setError(err.message)
        },
        fetchPolicy: 'network-only'
    })


    const onFinish = values => {
        setError(false);
        const tracking = form.getFieldValue().trackingNo

        getPaymentStatus({
            variables: {
                trackingNo: +tracking
            }
        })
    };

    const errorMessageOnJWTExpired=(
        <>
          This link has expired. Please reach out to <a href='/contactus'>Customer Service</a> to request a new email to be sent.
        </>
    )

    useEffect(()=>{
        if(location.state?.message){
            if(location.state?.message.includes("jwt expired")){
                setMessageFromRedirect(<Alert message={errorMessageOnJWTExpired} type="error" />)
            }
            else{
                setStatus(location.state.message); 
            }   
        }
    },[location])



    return (
        <Fragment>
            <NavigationBar/>
            <Container className="pb-4">
                <Row className="text-white bg-SunPowerBlue">
                    <Col lg={6} className="p-5 align-self-center order-2 order-lg-1">
                        <h2 className="text-white text-uppercase">Track Your Payment</h2>
                        <h4 className="text-white">Enter your tracking number to see the status of your payment.</h4>
                        <p className="fw-bold">Your tracking number can be found in an email after installation is complete</p>
                    </Col>
                    <Col lg={6} className='px-0 order-1 order-lg-2'>
                        <Image fluid src="images/Header-TrackPayment.png" className="h-100 w-100" />
                    </Col>
                </Row>
            </Container>
            <Styles>
                <main>
                    <div className="container border p-5">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <p className="font-weight-bold border-bottom">{t("tracking_no")}:</p>
                                <Form
                                    form={form}
                                    name="basic"
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        name="trackingNo"
                                        rules={[{
                                            required: true, message: "Please input your tracking number",
                                        },{
                                            pattern: patterns.phoneNumberCheckPattern,
                                            message: "Value should contain only numbers.",
                                          }]}
                                    >
                                        <Input className="w-100" placeholder={t('enter_tracking_no')} />
                                    </Form.Item>
                                    <Form.Item {...tailLayout}>
                                        <Button type="primary" htmlType="submit">
                                            {t('track_now')}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                            <div className="col-6">
                                <p className="font-weight-bold border-bottom">{t('current_status')}:</p>
                                <p>{status ?<Alert message={<span dangerouslySetInnerHTML={{ __html: status }} />} description= {messaging}type="success" /> : null}</p>
                                {messageFromRedirect&&<p>{messageFromRedirect}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {
                                    errorRes ?<Alert message={errorRes} type="error" /> : <p></p>
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </Styles>
            <Footer />
        </Fragment>

    );
}

export default TrackPayment;