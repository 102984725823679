import React, { Component, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from '@apollo/client';
import PeguPayment from './Components/PeguPayment';
import RedemptionForm from './Components/RedemptionForm';
import PeguConfirmation from './Components/PeguConfirmation';
import TrackPayment from './Components/TrackPayment';
import CustomerPayment from './Components/CustomerPayment';
import CustomerConfirmation from './Components/CustomerConfirmation';
import Faqs from './Components/Faqs';
import ContactUs from './Components/ContactUs';
import TokenRedeemedPegu from './Components/TokenRedeemedPegu';
import TokenRedeemedCustomer from './Components/TokenRedeemedCustomer'
import RedeemVirtual from './Components/RedeemVirtual';
import CardBalance from './Components/CardBalance';
import ReferralForm from './Components/ReferralForm';
import { Alert } from 'antd';
import ConfirmationForm from './Components/Confirmation';
import PhysicalCardContent from './Components/PhysicalCardContent';
import PhysicalCardRedeemed from './Components/PhysicalCardRedeemed';
import CampaignPage from './Components/CampaignPage';
import ShareSunPowerPathProvider, { RedirectSpd } from './Components/ShareSunPowerPathProvider';
import RetrieveLink from './Components/RetrieveMyLink/RetrieveLink';


function App() {

  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_NODE_URL,
    headers: { 'Apollo-Require-Preflight': 'true' }
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        token: token ? `${token}` : "",
      }
    }
  });
  const wsLink = new GraphQLWsLink(createClient({
    url: process.env.REACT_APP_NODE_URL_WSS,
    options: {
      reconnect: true,

    }
  }));

  const errorlink = onError(({ graphQLErrors, networkError, operation, forward, response }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      console.log(response);
    }
  });

  const httpLinkErrHandling = ApolloLink.from([
    errorlink,
    httpLink,
  ])

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    authLink.concat(httpLinkErrHandling)
  )

  const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache()
  });

  console.log(window.navigator)

  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Router>
          <Routes>
            <Route exact path="/pegu" element={<PeguPayment />} />
            <Route exact path="/peguconfirmation" element={<PeguConfirmation />} />
            <Route exact path="/customersatisfaction" element={<CustomerPayment />} />
            <Route exact path="/cussatconfirmation" element={<CustomerConfirmation />} />
            <Route exact path="/tokenredeemedpegu" element={<TokenRedeemedPegu />} />
            <Route exact path="/tokenredeemedcustomer" element={<TokenRedeemedCustomer />} />
            <Route exact path="/track" element={<TrackPayment />} />
            <Route exact path="/faqs" element={<Faqs />} />
            <Route exact path="/contactus" element={<ContactUs />} />
            <Route exact path="/" element={<ShareSunPowerPathProvider formName="sharesunpower" />} />
            {/* Redirect '/spd' to '/' */}
            {/* <Route exact path={"/SPD"} element={<ShareSunPowerPathProvider formName={"spd"} />} /> */}
            <Route path="/spd" element={<RedirectSpd />} />
            <Route exact path="/campaign/sharesunpower" element={<ShareSunPowerPathProvider formName={"sharesunpower"} />} />
            <Route exact path="/redeemvirtualcard" element={<RedeemVirtual />} />
            <Route exact path="/cardbalance" element={<CardBalance />} />
            <Route path="/redemption" element={<RedemptionForm />} />
            <Route exact path="/confirmation" element={<ConfirmationForm />} />
            <Route exact path='/redeemphysicalcard' element={<PhysicalCardContent />} />
            <Route exact path='/physicalcardredeemed' element={<PhysicalCardRedeemed />} />
            <Route exact path='/retrievelink' element={<RetrieveLink />} />
          </Routes>
        </Router>
      </div>
    </ApolloProvider>
  );
}
export default App;




