import React from 'react'
import { useState, useEffect } from 'react';
import { Container, Image, Row, Col, Button } from 'react-bootstrap';
import { gql, useQuery, useMutation } from '@apollo/client'
import { message, Radio, Spin, AutoComplete } from 'antd';
import { Form, Input, Select } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { cities, patterns, rewardTypes } from '../Constants';
import SSN from './formItems/SSN';


export default function RedemptionForm() {
const [options, setoptions] = useState(false);
 const [addressValidated, setAddressValidation] = useState(true);

  const GET_PAYMENTFORM_DATA = gql`
    query GetReferralDetailsByToken($token: String!) {
        getReferralDetailsByToken(token: $token) {
          _id
          referralFirstName
          referralLastName
          referralEmail
          referralPhone
          referralAddress1
          referralAddress2
          referralCity
          referralState
          referralZip
          fName
          lName
          email
          trackingNo
          leadID
          leadOutcome
          additionalComments
          installationStatus
          rewardType
          amount
          createdAt
          refererAddress1
          refererAddress2
          refererCity
          refererState
          refererZip
          emailSentDate
          redeemed
          redeemedDate
          shipDate
          refererPhone
          ssnCapture
        }
      }`;


  const UPDATE_PAYMENT = gql`
    mutation UpdateReferralByToken($rewardType: String!, $token: String!, $refererAddress1: String, $refererAddress2: String, $refererCity: String, $refererState: String, $refererZip: String,$refererPhone:String, $ssn:String) {
      updateReferralByToken(
        rewardType: $rewardType, 
            token: $token, 
            refererAddress1: $refererAddress1, 
            refererAddress2: $refererAddress2, 
            refererCity: $refererCity, 
            refererState: $refererState, 
            refererZip: $refererZip,
            refererPhone:$refererPhone,
            ssn:$ssn
           ) {
          message
        }
      }`;
      

  const [form] = Form.useForm();
  const { Option } = Select;
  const [token, setToken] = useState();
  const [virtualVisaDisable, setvirtualVisaDisable] = useState(false);
  const [PhyVisaDisable, setPhyVisaDisable] = useState(false);
  const [checkDisable, setCheckDisable] = useState(true);
  const [disable, setDisable] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [getParam, setParam] = useSearchParams()
  const [paymentReemed, setpaymentReemed] = useState(false);
  const redirect = useNavigate();
  const [disableEditableFields, setDisableEditableFields] = useState(false);
   let cleartime;
      const debounce = (lazyQuery) => {
        clearTimeout(cleartime)
        cleartime = setTimeout(() => {
          lazyQuery()
        }, 1000)
      }

  const checkPaymentType = (amount) => {
    if (amount > 1000) {
      setvirtualVisaDisable(true);
    }
    if (amount > 2500) {
      setPhyVisaDisable(true);
      setCheckDisable(false);
    }
  }

  const getPaymentData = useQuery(GET_PAYMENTFORM_DATA, {
    variables: {
      token: token
    }
  });

  const [updateIndividualPayment] = useMutation(UPDATE_PAYMENT)
      const AUTOCOMPLETEADDRESS=gql` 
      mutation AutocompleteAddress($address:String!){
        autoCompleteAddress(address: $address) {
          streetLine
          secondary
          city
          state
          zipcode
          entries
        }
      }`
      const[getAutoCompleteAddress]=useMutation(AUTOCOMPLETEADDRESS);

  useEffect(() => {

    setToken(getParam.get('token'))
    if (getPaymentData.error) {
      if (getPaymentData.error.message === 'Referral already redeemed!') {
        redirect('/track', { state: { message: "This payment has already been redeemed. You can use Track Payment on the left to track your payment." } });
      }
      else if (getPaymentData.error.message.includes('jwt expired')) {
        redirect('/track', { state: { message: "jwt expired" } });
      }
      else {
        message.error(getPaymentData?.error?.message)
      }
    }
    if (!getPaymentData.loading) {
      if (getPaymentData.data == undefined) {
        form.setFieldsValue({
          paymentType: '',
          ReferralLastName: '',
          ReferralFirstName: '',
          ZIP: '',
          State: '',
          City: '',
          RefererAddressLine2: '',
          RefererAddressLine1: '',
          RefererPhone: '',
          RefererEmail: '',
          RefererLastName: '',
          RefererFirstName: '',
          Amount: '',
          trackingNumber: '',
        })
        setpaymentReemed(true)
        setDisable(true)

      } else {
        form.setFieldsValue({
          paymentType: getPaymentData.data.getReferralDetailsByToken.rewardType,
          ReferralLastName: getPaymentData.data.getReferralDetailsByToken.referralLastName,
          ReferralFirstName: getPaymentData.data.getReferralDetailsByToken.referralFirstName,
          ZIP: getPaymentData.data.getReferralDetailsByToken.refererZip,
          State: getPaymentData.data.getReferralDetailsByToken.refererState,
          City: getPaymentData.data.getReferralDetailsByToken.refererCity,
          RefererAddressLine2: getPaymentData.data.getReferralDetailsByToken.refererAddress2,
          RefererAddressLine1: getPaymentData.data.getReferralDetailsByToken.refererAddress1,
          RefererEmail: getPaymentData.data.getReferralDetailsByToken.email,
          RefererLastName: getPaymentData.data.getReferralDetailsByToken.lName,
          RefererFirstName: getPaymentData.data.getReferralDetailsByToken.fName,
          Amount: getPaymentData.data.getReferralDetailsByToken.amount,
          trackingNumber: getPaymentData.data.getReferralDetailsByToken.trackingNo,
          RefererPhone: getPaymentData.data.getReferralDetailsByToken.refererPhone,

        })
        checkPaymentType(getPaymentData.data.getReferralDetailsByToken?.amount)
      }

    }
  }, [getPaymentData, token, getParam, form])
    const handleSearch = async (value) => {
        setAddressValidation(true);
        let data = await getAutoCompleteAddress({
          variables: { address: value },
        });
        let res = data.data.autoCompleteAddress.map((record) => ({
          value: `${record.streetLine},${record.secondary},${record.city},${record.state},${record.zipcode}`,
          label: `${record.streetLine},${record.secondary},${record.city},${record.state},${record.zipcode}`,
        }));
        setoptions(res);
    }
     const onSelect = async (value, option) => {
        const addressParts = value.split(",");
        form.setFieldsValue({
          RefererAddressLine1: addressParts[0],
        });
        form.setFieldsValue({
          RefererAddressLine2: addressParts[1],
        });
        form.setFieldsValue({
          mailingCity: addressParts[2],
        });
        form.setFieldsValue({
          mailingState: addressParts[3],
        });
        form.setFieldsValue({
          mailingZip: addressParts[4],
        });
      };

  const verifyHandler = () => {
    form.validateFields(["RefererAddressLine1", "City", "State", "ZIP", "paymentType", "RefererPhone", "ssn"]).then((res) => {
      if (res) {
        setDisable(true);
        setHidden(false);
        setDisableEditableFields(true);
      }
      window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
    })
  }

  const paymentFormHandler = async () => {
    const { paymentType, ZIP, State, City, RefererAddressLine2, RefererAddressLine1, RefererPhone, trackingNumber, ssn } = form.getFieldValue();
    try {
      const res = await updateIndividualPayment({
        variables: {
          ssn: ssn?.replace(/-/g, ''),
          rewardType: paymentType,
          token: token,
          refererAddress1: RefererAddressLine1,
          refererAddress2: RefererAddressLine2,
          refererState: State,
          refererCity: City,
          refererZip: ZIP,
          refererPhone: RefererPhone,
        }
      })
      if (res) {
        redirect('/track', { state: { message: "Your reward type was successfully submitted. You can use the Track Payment on the Left to track your payment.", trackingNumber: trackingNumber } });

      }
    } catch (error) {
      message.error({
        content: getPaymentData.error.message,
        style: {
          marginTop: "10vh",
          fontSize: "1vw",
        },
        duration: 10,
      });
    }
  }


  return (
    <>
      <Container fluid className="p-4">
        <Row className="ms-5">
          <Col md={2}>
            <Image fluid src="images/SunPower.png" className="nav-logo" />
          </Col>
          <Col md={2}></Col>
        </Row>
      </Container>
      <Container>
        <Row className="bg-SunPowerBlue p-2">
          <Col>
            <h2 className="text-white">Payment Redemption Form</h2>
          </Col>
        </Row>
        <Row className="py-2">
          <Col>
            <p>Fill out the form to claim your payments.</p>
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            {getPaymentData.loading ? (
              <Spin
                className=" spin d-flex align-items-center justify-content-center"
                size="large"
              ></Spin>
            ) : (
              <Form
                form={form}
                layout="vertical"
                name="dynamic_rule"
                onFinish={paymentFormHandler}
              >
                <h5>Your Information</h5>
                 {getPaymentData?.data?.getReferralDetailsByToken
                  .ssnCapture && (
                    <Row>
                      <Col>
                        <SSN disabled={disableEditableFields} />
                      </Col>
                    </Row>
                  )} 
                <Row>
                  <Col>
                    <Form.Item
                      as={Col}
                      className="mb-3"
                      label={"Tracking Number"}
                      name={"trackingNumber"}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      as={Col}
                      className="mb-3"
                      label="Amount"
                      name="Amount"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      as={Col}
                      className="mb-3"
                      label="Referrer First Name"
                      name="RefererFirstName"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      as={Col}
                      className="mb-3"
                      label="Referrer Last Name"
                      name="RefererLastName"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      as={Col}
                      className="mb-3"
                      label="Referrer Email"
                      name="RefererEmail"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      as={Col}
                      className="mb-3"
                      label="Referrer Phone"
                      name="RefererPhone"
                      rules={[
                        {
                          required: true,
                          message: "Referrer Phone is required",
                        },
                        {
                          pattern: patterns.phoneNumberCheckPattern,
                          message: "Value should contain just number.",
                        },
                        {
                          min: 10,
                          message: "Phone number should be of 10 digit long.",
                        },
                        {
                          max: 10,
                          message: "Phone number should be of 10 digit long.",
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      as={Col}
                      className="mb-3"
                      label="Referrer Address Line 1"
                      name="RefererAddressLine1"
                      rules={[
                        {
                          required: true,
                          message: "Referrer Address Line 1 is required",
                        },
                      ]}
                    >
                       <AutoComplete
                                onKeyDown={(e) => {
                                                    debounce(() => handleSearch(e.target.value));
                                                }}
                                                onSelect={onSelect}
                                                placeholder="Enter Referer AddressLine 1"
                                                options={options}
                                                disabled={disable}
                                            />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      as={Col}
                      className="mb-3"
                      label="Referrer Address Line 2"
                      name="RefererAddressLine2"
                    >
                      <Input disabled={disableEditableFields} autocomplete="address-line"/>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      as={Col}
                      className="mb-3"
                      label="City"
                      name="City"
                      rules={[
                        {
                          required: true,
                          message: "City is required",
                        },
                        {
                          pattern: patterns.onlyChar,
                          message:
                            "Special character and numbers are not allowed",
                        },
                      ]}
                    >
                      <Input disabled={disableEditableFields} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-3 border-bottom pb-2">
                  <Col>
                    <Form.Item
                      as={Col}
                      className="mb-3"
                      label="State"
                      name="State"
                      rules={[
                        {
                          required: true,
                          message: "State is required",
                        },
                      ]}
                    >
                      <Select
                        disabled={disableEditableFields}
                        showSearch
                        placeholder="Select a State"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {cities.map((city, index) => {
                          return (
                            <Option key={index} value={city.value}>
                              {city.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      as={Col}
                      className="mb-3"
                      label="ZIP"
                      name="ZIP"
                      rules={[
                        {
                          required: true,
                          message: "Zip is required",
                        },
                        {
                          pattern: patterns.zipCheck,
                          message: "Zip should only contain numbers.",
                        },
                        {
                          min: 5,
                          message: "Zip should be of 5 digit long.",
                        },
                        {
                          max: 5,
                          message: "Zip should be of 5 digit long.",
                        },
                      ]}
                    >
                      <Input disabled={disableEditableFields} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-3 border-bottom pb-2">
                  <Col>
                    <h5>Referral Information</h5>
                    <Row className="mb-2">
                      <Col>
                        <Form.Item
                          as={Col}
                          className="mb-3"
                          label="Referral First Name"
                          name="ReferralFirstName"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          as={Col}
                          className="mb-3"
                          label="Referral Last Name"
                          name="ReferralLastName"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <h5>Select Your Payment Type:</h5>
                  </Col>
                  <Col>
                    <Form.Item
                      label="Reward Type"
                      name="paymentType"
                      rules={[
                        {
                          required: true,
                          message: "Reward type is required",
                        },
                      ]}
                    >
                      <Radio.Group className="d-flex justify-content-around">
                        <Col md={3}>
                          <Radio
                            value={rewardTypes.virtual}
                            disabled={virtualVisaDisable || disable}
                          >
                            Virtual Visa
                            <Image
                              fluid
                              className="radio-images d-block"
                              src="images/SunPower Visa Blue.png"
                            />
                          </Radio>
                        </Col>
                        <Col md={3}>
                          <Radio
                            value={rewardTypes.physical}
                            disabled={PhyVisaDisable || disable}
                          >
                            Physical Visa
                            <Image
                              fluid
                              className="radio-images d-block"
                              src="images/SunPower Visa Silver.png"
                            />
                          </Radio>
                        </Col>
                        <Col md={3} hidden={checkDisable}>
                          <Radio
                            value={rewardTypes.check}
                            disabled={disable}
                          >
                            Check
                            <Image
                              fluid
                              className="radio-images d-block"
                              src="images/checklogosunpower.png"
                            />
                          </Radio>
                        </Col>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="py-5 justify-content-center" hidden={disable}>
                  <Col sm={2}>
                    <Button
                      onClick={verifyHandler}
                      disabled={paymentReemed}
                      className="powur-btn-primary w-100"
                    >
                      Continue
                    </Button>
                  </Col>
                </Row>
                <Row className="py-5 justify-content-center" hidden={hidden}>
                  <Col md={3}>
                    <Button
                      className="powur-btn-primary w-100"
                      onClick={() => {
                        setHidden(true);
                        setDisable(false);
                        setDisableEditableFields(false);
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                  <Col md={3}>
                    <Button type="submit" className="powur-btn-primary w-100">
                      submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
