import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useState, useHistory } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import CampaignPage from "./CampaignPage";

const GET_LANDING_PAGE_DATA = gql`
  query GetLandingPageHeaders {
    getLandingPageHeaders {
      campaignName
      campaignCode
      amount
      startDate
      endDate
      isActive
      header
      landingPageUrl
      disclaimer1
      disclaimer2
      disclaimer3
      disclaimer4
      disclaimer5
      disclaimer6
    }
  }
`;
const CHECK_ENERGY_CONSULTANT_REDIRECT_URL = gql`
  query Query($name: String!) {
    checkEnergyConsultantRedirectUrl(name: $name)
  }
`;

export const RedirectSpd = () => {
  const location = useLocation();
  const state = {
    isRedirectedFromSpd: true,
    refemail: new URLSearchParams(location.search).get("refemail"),
  };
  return <Navigate to="/" state={state} />;
};

const ShareSunPowerPathProvider = (props) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const isSPD = location.pathname.includes("SPD");
  const { formName } = props;
  const energyconsultant = searchParams.get("name");
  const isRedirectedFromSpd = location.state && location.state.isRedirectedFromSpd;
  const refemail = location.state && location.state.refemail;

  // Variables to render filtered data
  const [spd, setSpd] = useState();
  const [shareSunPower, setShareSunPower] = useState();
  // api to check if redirect
  const { data: redirectUrl, loading: redirectUrlLoading } = useQuery(CHECK_ENERGY_CONSULTANT_REDIRECT_URL, {
    variables: { name: energyconsultant },
    skip: !energyconsultant,
  });

  // Get campaign data , filter and pass on complete.
  const { data, loading } = useQuery(GET_LANDING_PAGE_DATA, {
    onCompleted(data) {
      const campaignData = data?.getLandingPageHeaders;

      // filter for sharesunpower data
      const shareSunPower = campaignData.filter((obj) => obj.campaignName.includes("Share SunPower"));

      // filter for spd data
      const spd = campaignData.filter((obj) => obj.campaignName.includes("SPD"));

      setSpd(spd);
      setShareSunPower(shareSunPower);
      //console.log(spd);
    },
  });
  // to redirect user if found redirection url
  useEffect(() => {
    if (redirectUrl && !redirectUrlLoading) {
      window.location.replace(redirectUrl.checkEnergyConsultantRedirectUrl);
    }
  }, [redirectUrl, isSPD, redirectUrlLoading]);

  return formName.toLowerCase() === "spd" ? (
    data && !loading && spd ? (
      <CampaignPage
        campaignHeader={"Help make our world a brighter place!"}
        campaignCode={"CODE2"}
        campaignName={"Refer SunPower SPD"}
        referralLeadSource={"spd"}
        campaignBody={spd[0]?.header}
        campaignDisclaimer={[
          spd[0]?.disclaimer1,
          spd[0]?.disclaimer2,
          spd[0]?.disclaimer3,
          spd[0]?.disclaimer4,
          spd[0]?.disclaimer5,
          spd[0]?.disclaimer6,
        ]}
        heroImage={"SPD"}
        addnPlaceHolder={"Who was your Energy Consultant?"}
      />
    ) : (
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100vw",
            height: "100vh",
          },
        }}
        fadeSpeed={500}
        text="Loading..."
      />
    )
  ) : formName.toLowerCase() === "sharesunpower" ? (
    data && !loading && shareSunPower ? (
      <CampaignPage
        campaignHeader={"Help make our world a brighter place!"}
        campaignCode={"CODE3"}
        campaignName={"Share SunPower - Customer Referrals"}
        referralLeadSource={"sharesunpower"}
        campaignBody={shareSunPower[0]?.header}
        campaignDisclaimer={[
          shareSunPower[0]?.disclaimer1,
          shareSunPower[0]?.disclaimer2,
          shareSunPower[0]?.disclaimer3,
          shareSunPower[0]?.disclaimer4,
          shareSunPower[0]?.disclaimer5,
          shareSunPower[0]?.disclaimer6,
        ]}
        heroImage={"shareSunPower"}
        addnPlaceHolder={"Additional Comments"}
        isRedirectedFromSpd={isRedirectedFromSpd}
        refemail={refemail}
      />
    ) : (
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100vw",
            height: "100vh",
          },
        }}
        fadeSpeed={500}
        text="Loading..."
      />
    )
  ) : (
    <Navigate to="/track" />
  );
};

export default ShareSunPowerPathProvider;
