import React, { useState, Fragment, useEffect } from "react";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "./PeguPayments-style.css";
import Footer from "./Footer.js";
import styled from "styled-components";
import queryString from "query-string";
import LoadingOverlay from "react-loading-overlay";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import { Address1, Address2, City, Email, FirstName, LastName, Phone, State, Zip } from "./PhysicalCardContent/formitems";
import { Form } from "antd";
import { getBpsAccountUrl } from "../HelperFunctions/General.js"

const SHOW_DATA = (token) => `
query {
    physicalCardAddress(token: "${token}") {
        fname
        lname
        email
        phone
        address1
        address2
        city
        state
        zip
        status
    }
}
`;

const REDEEM_PHYSICAL = (
  token,
  fname,
  lname,
  email,
  phone,
  address1,
  address2,
  city,
  state,
  zip
) => `
    mutation {
        physicalCardRedeem(input: {token:"${token}", fname:"${fname}", lname: "${lname}", email: "${email}", phone: "${phone}", address1: "${address1}", address2: "${address2}", city: "${city}", state: "${state}", zip: "${zip}"}) {
            message
            error
        }
    }
`;
const axiosGraphQL = axios.create({
  baseURL: process.env.REACT_APP_SYSTEM_URL,
});

const Styles = styled.div`
  p {
    font-size: 1.375em;
    font-weight: 400;
  }
  body {
    font-family: "Mulish", sans-serif;
    font-size: 100%;
  }
  .fs-60 {
    font-size: 3.75em;
    font-weight: 700;
  }

  .fs-48 {
    font-size: 3em;
    font-weight: 700;
  }

  .fs-36 {
    font-size: 2.25em;
    font-weight: 700;
  }

  .fs-26 {
    font-size: 1.625em;
    font-weight: 600;
  }

  .fs-20 {
    font-size: 1.25em;
  }

  .fs-16 {
    font-size: 1em;
  }

  /* Buttons */

  .bps-primary-button {
    background-color: #1c304c;
    border: none;
    border-radius: 0%;
    font-size: 18px;
  }

  .bps-primary-button:hover,
  .bps-primary-button:active,
  .bps-primary-button:focus {
    background-color: #4787c2;
  }

  /* Colors */

  .bps-bg-light {
    background-color: #faf5f0;
  }

  .bps-bg-midnight {
    background-color: #1c304c;
  }

  .bps-midnight-tint {
    color: #65687d;
  }

  .bps-midnight {
    color: #1c304c;
  }

  .bps-sky {
    color: #4787c2;
  }

  .footer {
    height: 140px;
  }

  .footer-nav li {
    list-style: none;
    display: inline;
    padding: 0px 10px;
    text-align: center;
  }

  .footer-text {
    font-size: 1em;
    text-align: center;
    margin-top: calc(40px - 0.5em);
  }
`;

const PhysicalCardContent = (props) => {
  const values = queryString.parse(window.location.search);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showSpinner2, setShowSpinner2] = useState(false);
  const [spinnerText, setSpinnerText] = useState({
    text: "Loading your data...",
  });
  const [showSuccessMessage, setshowSuccessMessage] = useState(false);
  const [showErrorMessage, setshowErrorMessage] = useState(false);
  const [hideFields, setHideFields] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [form] = Form.useForm();
  const mqSiteUrl = process.env.REACT_APP_MQ_URL

  useEffect(() => {
    if (mqSiteUrl) {
      // Redirect to the specified URL if REACT_APP_MQ_URL is present
      setSpinnerText({ text: "Redirecting to Cardholder site..." })
      window.location.replace(getBpsAccountUrl(values.token, "visareward"))
    }
  }, [values.token, mqSiteUrl])

  useEffect(() => {
    try {
      axiosGraphQL
        .post("", {
          query: SHOW_DATA(values.token),
        })
        .then((result) => {
          console.log(result);
          setShowSpinner(false);
          if (result.data.data.physicalCardAddress.status !== "success") {
            if (
              result.data.data.physicalCardAddress.status ===
              "This token has already been redeemed and cannot be redeemed again"
            ) {
              setHideFields(true);
            }
            setshowErrorMessage(true);
            seterrorMessage(result.data.data.physicalCardAddress.status);
          } else {
            form.setFieldsValue(result.data.data.physicalCardAddress);
            setshowErrorMessage(false);
          }
        });
    } catch (err) {
      console.log(err);
      console.log("failed");
    }
  }, []);

  function handleClick(formValues) {
    setShowSpinner2(true);
    setSpinnerText({ text: "Your card request is being processed..." });
    axiosGraphQL
      .post("", {
        query: REDEEM_PHYSICAL(
          values.token,
          formValues.fname,
          formValues.lname,
          formValues.email,
          formValues.phone,
          formValues.address1,
          formValues.address2,
          formValues.city,
          formValues.state,
          formValues.zip
        ),
      })
      .then((result) => {
        setShowSpinner2(false);
        if (result.data.data.physicalCardRedeem.error) {
          setshowErrorMessage(true);
          seterrorMessage(result.data.data.physicalCardRedeem.message);
        } else {
          window.location = "/physicalcardredeemed";
          setshowErrorMessage(false);
          setshowSuccessMessage(true);
          seterrorMessage(result.data.data.physicalCardRedeem.message);
        }
      });
  }

  return (
    <Fragment>
      <LoadingOverlay
        active={showSpinner || showSpinner2}
        spinner
        text={spinnerText.text}
      >
        <Styles>
          <Container fluid className="p-4">
            <Row>
              <Col md={2}>
                <Image fluid src="images/SunPower.png" className="nav-logo" />
              </Col>
            </Row>
          </Container>
          <Container fluid className="pt-5">
            <Row className="py-5 bps-bg-light justify-content-center text-center">
              <Col md={6} className="pt-5">
                <h2 className="bps-midnight fs-48">
                  Your Physical Visa Reward Card is just a click away.
                </h2>
                <p className="fs-20">
                  Verify your information and mailing address to receive your
                  reward card.
                </p>
              </Col>
            </Row>
            <Row className="py-5 justify-content-center">
              <Col md={6}>
                <Form
                  form={form}
                  className="row gx-3 justify-content-center"
                  layout="vertical"
                  requiredMark={false}
                  onFinish={handleClick}
                >
                  <h4 className="fs-20 fw-bold bps-midnight">
                    Your Information
                  </h4>
                  <Alert
                    color="success"
                    style={{ display: showSuccessMessage ? "block" : "none" }}
                  >
                    You should receive your card in 10-15 business days.
                  </Alert>
                  <Alert
                    color="danger"
                    style={{ display: showErrorMessage ? "block" : "none" }}
                  >
                    {ReactHtmlParser(errorMessage)}
                  </Alert>
                  <div className="col-sm-6">
                    <FirstName />
                  </div>
                  <div className="col-sm-6">
                    <LastName />
                  </div>
                  <div className="col-sm-6">
                    <Email />
                  </div>
                  <div className="col-sm-6">
                    <Phone />
                  </div>

                  <h4 className="fs-20 fw-bold bps-midnight pt-4">
                    Mailing Address
                  </h4>

                  <div className="col-sm-6">
                    <Address1 />
                  </div>
                  <div className="col-sm-6">
                    <Address2 />
                  </div>
                  <div className="col-sm-6">
                    <City />
                  </div>
                  <div className="col-sm-3">
                    <State />
                  </div>
                  <div className="col-sm-3">
                    <Zip />
                  </div>
                  <div style={{ display: hideFields ? "none" : "block" }}>
                    <Button
                      type="submit"
                      className="ant-btn ant-btn-default OrangeBtn col-md-2 mt-4"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
          <Footer />
        </Styles>
      </LoadingOverlay>
    </Fragment>
  );
};

export default PhysicalCardContent;
