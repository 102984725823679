import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Form, Input, Button, Select, Alert } from 'antd';
import NavigationBar from './NavigationBar';
import Footer from './Footer';
import 'antd/dist/antd.css';
import './PeguPayments-style.css';
import styled from 'styled-components';
import { cities, patterns } from '../Constants';
import { useLazyQuery, useMutation } from "@apollo/client";
import { gql } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkForEmptySpaces } from '../HelperFunctions/General';



const SAVE_REFERAL_FORM = gql`
  mutation createReferral(
    $referralFirstName: String!
    $referralLastName: String!
    $referralEmail: String!
    $referralPhone: String!
    $referralCity: String!
    $referralState: String!
    $fName: String!
    $lName: String!
    $email: String!
    $referralAddress1: String
    $referralAddress2: String
    $referralZip: String
    $additionalComments: String
    $refererAddress1: String!
    $refererAddress2: String
    $refererCity: String!
    $refererState: String!
    $refererZip: String!
    $refererPhone: String!
    $campaignName: String!
    $campaignCode: String!
  ) {
    createReferral(
      input: {
        referralFirstName: $referralFirstName
        referralLastName: $referralLastName
        referralEmail: $referralEmail
        referralPhone: $referralPhone
        referralCity: $referralCity
        referralState: $referralState
        fName: $fName
        lName: $lName
        email: $email
        referralAddress1: $referralAddress1
        referralAddress2: $referralAddress2
        referralZip: $referralZip
        additionalComments: $additionalComments
        refererAddress1: $refererAddress1
        refererAddress2: $refererAddress2
        refererCity: $refererCity
        refererState: $refererState
        refererZip: $refererZip
        refererPhone: $refererPhone
        campaignName: $campaignName
        campaignCode: $campaignCode
      }
    ) {
      id
      referralFirstName
      referralLastName
      referralEmail
      referralPhone
      referralAddress1
      referralAddress2
      referralCity
      referralState
      referralZip
      fName
      lName
      email
      additionalComments
      trackingNo
    }
  }
`;


const emailIdCheck = gql`
    query EmailCheck($referralEmail: String) {
        emailCheck(referralEmail: $referralEmail) {
        isFound
        id
        }
    }
`;


const ReferralForm = () => {

  const { Option } = Select;
  const [form] = Form.useForm();
  const redirect = useNavigate();
  const [saveReferalForm] = useMutation(SAVE_REFERAL_FORM)
  const [status, setStatus] = useState();
  const [errorRes, setError] = useState();
  const [disable, setDisable] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [EmailConfirmed, setEmailCheck] = useState(false);
  const [refEmail, setReferralEmail] = useState();

  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("initialUrl", location.pathname);
  }, [])

  const onCheck = () => {
    form.validateFields().then((res) => {
      if (res) {
        setDisable(true)
        setHidden(false)
      }
    })
  }

  const submitHandler = async () => {
    const { referralFirstName,
      referralLastName,
      referralEmail,
      referralPhone,
      referralAddress1,
      referralAddress2,
      referralCity,
      referralState,
      referralZip,
      fName,
      lName,
      email,
      additionalComments,
      refererAddress1,
      refererAddress2,
      refererCity,
      refererState,
      refererZip,
      referrerPhone } = form.getFieldValue()
    try {
      const res = await saveReferalForm({
        variables: {
          referralFirstName,
          referralLastName,
          referralEmail,
          referralPhone,
          referralAddress1,
          referralAddress2,
          referralCity,
          referralState,
          referralZip,
          fName,
          lName,
          email,
          additionalComments,
          refererAddress1,
          refererAddress2,
          refererCity,
          refererState,
          refererZip,
          refererPhone: referrerPhone,
          campaignName: "NEM Customer Comms 1",
          campaignCode: "CODE1",
        },
      });

      if (res) {
        setStatus('Data Submitted Successfully!')
        window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
        redirect('/confirmation', { state: { message: 'Your referral request has been successfully submitted!' } })
      }
    } catch (err) {
      window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
      setError(err.message)
    }
  }

  const checkingEmail = () => {
    const referralEmailVal = form.getFieldValue("referralEmail");
    setReferralEmail(referralEmailVal);
  }

  const [checkDuplicateEmail] = useLazyQuery(emailIdCheck)

  useEffect(() => {
    const validationSerialNum = setTimeout(async () => {
      if (refEmail != null || refEmail != undefined) {
        const res = await checkDuplicateEmail({
          variables: {
            "referralEmail": refEmail
          }
        });
        let validate = await res.data.emailCheck.isFound;
        setEmailCheck(validate);
        form.validateFields(["referralEmail"]);
      }

    }, 1000)
    return () => clearTimeout(validationSerialNum);
  }, [refEmail, EmailConfirmed])


  const Styles = styled.div`

    .firstNameStyle::-webkit-input-placeholder{
        background: -webkit-linear-gradient(left, #AAA 0%, #AAA 117px,red 20px, red 100%);
    }
    .lastNameStyle::-webkit-input-placeholder{
        background: -webkit-linear-gradient(left, #AAA 0%, #AAA 117px,red 20px, red 100%);
    }

    .emailStyle::-webkit-input-placeholder{
        background: -webkit-linear-gradient(left, #AAA 0%, #AAA 35px,red 20px, red 100%);
    }

    .phoneStyle::-webkit-input-placeholder{
        background: -webkit-linear-gradient(left, #AAA 0%, #AAA 40px,red 20px,  red 100%);
    }

    .cityStyle::-webkit-input-placeholder{
        background: -webkit-linear-gradient(left, #AAA 0%, #AAA 25px,red 20px, red 100%);
    }

    .ant-select-selection-placeholder{
        background: -webkit-linear-gradient(left, #AAA 0%, #AAA 33px,red 20px, red 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .firstName::-webkit-input-placeholder{
        background: -webkit-linear-gradient(left, #AAA 0%, #AAA 67px,red 20px, red 100%);
       
    }
    .lastName::-webkit-input-placeholder{
        background: -webkit-linear-gradient(left, #AAA 0%, #AAA 67px,red 20px, red 100%);
    }

    .zipStyle::-webkit-input-placeholder{
        background: -webkit-linear-gradient(left, #AAA 0%, #AAA 20px,red 20px, red 100%);
    }

    .referrerAddStyle::-webkit-input-placeholder{
        background: -webkit-linear-gradient(left, #AAA 0%, #AAA 185px,red 20px, red 100%);
    }
    .refAdd1::-webkit-input-placeholder{
        background: -webkit-linear-gradient(left, #AAA 0%, #AAA 60px,red 20px, red 100%);
    }
    
    .asterisk::-webkit-input-placeholder{
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
 
    `;

  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        func.apply(this, args);
        timerId = null;
      }, delay);
    };
  }

  const debouncedCheckingEmail = debounce(checkingEmail, 1000);

  return (
    <>
      <Container fluid className="px-0 overflow-hidden">
        <NavigationBar />
        <Container>
          <Row className="text-white bg-SunPowerBlue">
            <Col lg={6} className="px-0">
              <Image
                fluid
                src="images/Header-Referral.png"
                className="ms-0"
              />
            </Col>
            <Col lg={6} className="p-5 align-self-center">
              <h2 className="text-white display-4 text-capitalize">
                Your California friends and family will thank you
              </h2>
              <p className="text-white fs-5">
                Refer your California friends and family to SunPower. They’ll
                get the chance to maximize their solar savings and you’ll earn
                a $500 VISA Reward Card when their SunPower installation is
                complete.
              </p>
            </Col>
          </Row>
        </Container>
        <Container className="pt-2">
          <Row className="pt-2">
            <Col>
              <Styles>
                <h6 className="text-center">
                  SunPower appreciates your referral. Who would you like us to
                  reach out to?
                </h6>
                <Row>
                  <Col className="mt-3 mb-3">
                    {status ? (
                      <Alert message={status} type="success" />
                    ) : errorRes ? (
                      <Alert message={errorRes} type="error" />
                    ) : null}
                  </Col>
                  <Col></Col>
                </Row>
                <Form
                  form={form}
                  layout="vertical"
                  name="dynamic_rule"
                  onFinish={submitHandler}
                >
                  <Row className="text-white">
                    <Col lg={6}>
                      <Form.Item
                        name="referralFirstName"
                        rules={[
                          {
                            required: true,
                            message: "Referral First Name is required",
                          }, {
                            validator: checkForEmptySpaces
                          }
                        ]}
                      >
                        <Input
                          disabled={disable}
                          placeholder="Referral First Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={6} className="px-lg-0">
                      <Form.Item
                        name="referralLastName"
                        rules={[
                          {
                            required: true,
                            message: "Referral Last Name is required",
                          },
                          {
                            validator: checkForEmptySpaces
                          }
                        ]}
                      >
                        <Input
                          disabled={disable}
                          placeholder="Referral Last Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={6}>
                      <Form.Item
                        name="referralEmail"
                        validateFirst={true}
                        rules={[
                          {
                            required: true,
                            message: "Referral Email is required",
                          },
                          {
                            type: 'email',
                            message: "Please enter a valid email address",
                          },
                          {
                            validator: checkForEmptySpaces
                          },
                          {
                            validator: (_, value) => {
                              return new Promise((resolve, reject) => {
                                debouncedCheckingEmail();
                                resolve();
                              });
                            }
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (EmailConfirmed) {
                                return Promise.reject("Referral email already exists");
                              } else {
                                return Promise.resolve();
                              }
                            }
                          }),

                        ]}
                      >
                        <Input
                          disabled={disable}
                          placeholder="Email"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={6} className="px-lg-0">
                      <Form.Item
                        name="referralPhone"
                        rules={[
                          {
                            required: true,
                            message: "Referral Phone is required",
                          },
                          {
                            validator: checkForEmptySpaces
                          },
                          {
                            pattern: patterns.phoneNumberCheckPattern,
                            message:
                              "Phone number must only contain numbers.",
                          },
                          {
                            min: 10,
                            message: "Phone number must be 10 digits.",
                          },
                          {
                            max: 10,
                            message: "Phone number must be 10 digits.",
                          },
                        ]}
                      >
                        <Input disabled={disable} placeholder="Phone" />
                      </Form.Item>
                    </Col>
                    <Col lg={6}>
                      <Form.Item
                        name="referralAddress1"
                      // rules={[
                      //     {
                      //         required: true,
                      //         message:"Referral Address 1 is required",
                      //     },
                      // ]}
                      >
                        <Input disabled={disable} placeholder="Address 1" />
                      </Form.Item>
                    </Col>
                    <Col lg={6} className="px-lg-0">
                      <Form.Item name="referralAddress2">
                        <Input disabled={disable} placeholder="Address 2" />
                      </Form.Item>
                    </Col>
                    <Col lg={6}>
                      <Form.Item
                        name="referralCity"
                        rules={[
                          {
                            required: true,
                            message: "Referral City is required",
                          },
                          {
                            validator: checkForEmptySpaces
                          },
                          {
                            pattern: patterns.onlyChar,
                            message:
                              "Special characters and numbers are not allowed",
                          },
                        ]}
                      >
                        <Input disabled={disable} placeholder="City" />
                      </Form.Item>
                    </Col>
                    <Col lg={3} className="px-lg-0">
                      <Form.Item
                        name="referralState"
                        rules={[
                          {
                            required: true,
                            message: "Referral State is required",
                          },
                          {
                            validator: checkForEmptySpaces
                          }
                        ]}
                      >
                        <Select
                          virtual={false}
                          disabled={disable}
                          placeholder="State"
                        >
                          {cities.map((city, index) => {
                            return (
                              <Option key={index} value={city.value}>
                                {city.value}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={3} className="ps-lg-1 pe-lg-0">
                      <Form.Item
                        name="referralZip"

                      // rules={[
                      //     {
                      //         required: true,
                      //         message: "Referral Zip is required",
                      //     },
                      //     {
                      //         pattern: patterns.zipCheck,
                      //         message: "Value must only contain numbers."
                      //     },
                      //     {
                      //         min: 5,
                      //         message: "Referral zip must be 5 digits.",
                      //     },
                      //     {
                      //         max: 5,
                      //         message: "Referral zip must be 5 digits.",
                      //     },
                      // ]}
                      >
                        <Input disabled={disable} placeholder="Zip" />
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <h6 className="text-center">Your Information</h6>
                    </Col>
                    <Col lg={6}>
                      <Form.Item
                        name="fName"
                        rules={[
                          {
                            required: true,
                            message: "First Name is required",
                          },
                          {
                            validator: checkForEmptySpaces
                          }
                        ]}
                      >
                        <Input disabled={disable} placeholder="First Name" />
                      </Form.Item>
                    </Col>
                    <Col lg={6} className="px-lg-0">
                      <Form.Item
                        name="lName"
                        rules={[
                          {
                            required: true,
                            message: "Last Name is required",
                          },
                          {
                            validator: checkForEmptySpaces
                          }
                        ]}
                      >
                        <Input disabled={disable} placeholder="Last Name" />
                      </Form.Item>
                    </Col>
                    <Col lg={6}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Email is required",
                          },
                          {
                            type: 'email',
                            message: "Please enter a valid email address",
                          },
                          {
                            validator: checkForEmptySpaces
                          }
                        ]}
                      >
                        <Input disabled={disable} placeholder="Email" />
                      </Form.Item>
                    </Col>
                    <Col lg={6} className="px-lg-0">
                      <Form.Item
                        name="referrerPhone"
                        rules={[
                          {
                            validator: checkForEmptySpaces
                          },
                          {
                            required: true,
                            message: "Referrer Phone is required",
                          },
                          {
                            pattern: patterns.phoneNumberCheckPattern,
                            message:
                              "Phone number must only contain numbers.",
                          },
                          {
                            min: 10,
                            message: "Phone number must be 10 digits.",
                          },
                          {
                            max: 10,
                            message: "Phone number must be 10 digits.",
                          },
                        ]}
                      >
                        <Input
                          disabled={disable}
                          placeholder="Referrer Phone"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={6}>
                      <Form.Item name="additionalComments">
                        <Input
                          disabled={disable}
                          placeholder="Additonal Comments"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} className='text-center'>
                      <h6>
                        Your Address
                      </h6>
                    </Col>
                    <Col lg={6}>
                      <Form.Item
                        name="refererAddress1"
                        rules={[
                          {
                            required: true,
                            message: "Your Address is required",
                          },
                          {
                            validator: checkForEmptySpaces
                          }
                        ]}
                      >
                        <Input
                          disabled={disable}
                          placeholder="Referrer Install Address Line 1"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={6} className="px-lg-0">
                      <Form.Item name="refererAddress2">
                        <Input
                          disabled={disable}
                          placeholder="Referrer Install Address Line 2"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={6}>
                      <Form.Item
                        name="refererCity"
                        rules={[
                          {
                            required: true,
                            message: "Your City is required",
                          },
                          {
                            validator: checkForEmptySpaces
                          },
                          {
                            pattern: patterns.onlyChar,
                            message:
                              "Special characters and numbers are not allowed",
                          },
                        ]}
                      >
                        <Input disabled={disable} placeholder="City" />
                      </Form.Item>
                    </Col>
                    <Col lg={3} className="px-lg-0">
                      <Form.Item
                        name="refererState"
                        rules={[
                          {
                            required: true,
                            message: "Your State is required",
                          },
                          {
                            validator: checkForEmptySpaces
                          }
                        ]}
                      >
                        <Select
                          virtual={false}
                          disabled={disable}
                          placeholder="State"
                        >
                          {cities.map((city, index) => {
                            return (
                              <Option key={index} value={city.value}>
                                {city.value}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={3} className="ps-lg-1 pe-lg-0">
                      <Form.Item
                        name="refererZip"
                        rules={[
                          {
                            required: true,
                            message: "Your Zip is required",
                          },
                          {
                            validator: checkForEmptySpaces
                          },
                          {
                            pattern: patterns.zipCheck,
                            message:
                              "Referrer zip must only contain numbers.",
                          },
                          {
                            min: 5,
                            message: "Referrer zip must be 5 digits.",
                          },
                          {
                            max: 5,
                            message: "Referrer zip must be 5 digits.",
                          },
                        ]}
                      >
                        <Input disabled={disable} placeholder="Zip" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} hidden={disable}>
                      <Form.Item>
                        <Button
                          className="OrangeBtn"
                          onClick={() => {
                            onCheck();
                          }}
                        >
                          Send Referral
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col md={1}>
                      <Form.Item>
                        <Button
                          hidden={hidden}
                          className="OrangeBtn"
                          onClick={() => {
                            setDisable(false);
                            setHidden(true);
                          }}
                        >
                          Back
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col md={1}>
                      <Button
                        hidden={hidden}
                        htmlType="submit"
                        className="OrangeBtn"
                      >
                        Confirm
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='small text-secondary border-top pt-4'>
                      <p>
                        Referral reward will be processed 30 days after referral’s installation is complete and will be issued approximately 2-4 weeks later. SunPower Visa Reward Card / Virtual card is issued by Pathward, N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. No cash access or recurring payments. Card can be used everywhere Visa debit cards are accepted. Virtual card can be used everywhere Visa debit cards are accepted online, or phone/mail orders. Card/Virtual card valid for up to 6/24 months; unused funds will forfeit after the valid thru date. Terms and conditions apply.
                      </p>
                      <p>

                        Rebate Terms: Before rebate costs will vary, depending on system specifications. Check with your SunPower installation contractor to confirm participation with this offer. This offer is only available to customers who purchase a new, complete SunPower Equinox system, excluding customers who purchase a new home with SunPower solar pre-installed. Rebate may not be applied to quotes on existing SunPower proposals or past purchases. Cannot be combined with other SunPower offers. One rebate per household. Rebate form must be completed and submitted to SunPower within 90 days of the installation date, along with required documents. Allow 3-7 weeks for processing. Late submissions and those submitted without proper documentation and signatures will be subject to delay or cancellation. Void where prohibited. Reward Card: Card / Virtual card is issued by Pathward, N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. No cash access or recurring payments. Card can be used everywhere Visa debit cards are accepted. Virtual card can be used everywhere Visa debit cards are accepted online, or phone/mail orders. Card/Virtual card valid for up to 6/24 months; unused funds will forfeit after the valid thru date. Terms and conditions apply.
                      </p>
                      <p>

                        © 2023 SunPower Corporation. All Rights Reserved. SUNPOWER and the SUNPOWER logo are registered trademarks of SunPower Corporation in the U.S.
                      </p>
                    </Col>
                  </Row>
                </Form>
              </Styles>
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
}
export default ReferralForm;
