export function checkForEmptySpaces(_, value) {
    if (value !== undefined && value?.length !== 0) {
        const result = value.trim().length === 0
        if (result) {
            return Promise.reject("Please remove any leading or trailing spaces");
        }
        else {
            return Promise.resolve();
        }
    }
    else {
        return Promise.resolve();
    }
}

export const getBpsAccountUrl = (token, type) => {
    const baseUrl = process.env.REACT_APP_MQ_URL
    return `${baseUrl}/create-account?redemptionToken=${token}&type=${type}`
}