import React from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import '../styles.css'

const Styles = styled.div`
.bg-SunPowerBlue {
	background-color: #0076be;
}
.payment-radio {
    margin-left: 150px;
}
.form-check-label {
    text-align: center important!;
}
.ant-radio-button {
    padding: 10px;
}
.OrangeBtn {
    margin-left: 400px;
	font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn:hover {
    
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.BlueBtn {
	font-weight: 500;
	background-color: #0076BE;
	color: #FFFFFF;
}

.BlueBtn:hover {
	background-color:#39607A;
	color: #FFFFFF;
}

.BlueBtn:active {
	background-color:#39607A;
	color: #FFFFFF;
}
.BlueBtn:focus {
	background-color:#39607A;
	color: #FFFFFF;
}


.BlueBtnOutline {
	font-weight: 500;
	border: 1px solid #0076BE;
    background-color:#FFFFFF !important;
    color: #0076BE;
}

.BlueBtnOutline:hover {
	background-color:#0076BE !important;
	color: #FFFFFF;
}

.BlueBtnOutline:focus {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueBtnOutline:active {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueRadioBtn:hover {
	border: 1px solid #0076BE;
	color: #0076BE;
}

.BlueRadioBtn.active {
	border: 1px solid #0076BE;
	color: #0076BE;
}

footer{
	height: 80px;
	width: 100%;
	bottom:0;
    position: sticky;
    z-index: 9;
}


.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.divider-light {
	background-color: #E0E0E5;
	height: 2px;
	margin-bottom: 14px;
}

.divider-orange {
	background-color: #F18B21;
	height: 3.5px;
	width: 36px;
}

.divider-orange-lg {
	background-color: #F18B21;
	height: 3px;
	width: 100px;
}


.bg-banner1 {
	background-image: url("images/1200x627_banner no logo (1).png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
}

.bg-SunPowerBlack {
	background-color: hsla(0,0%,0%,0.60);
	color:#FFFFFF;
	text-align: center;
}



/*Nav*/
.nav-link.active {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}

.nav-link:hover {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}
/*Nav*/

/*Tabs*/

.nav-link.tab-title {
	color: #858585;
	background-color: #EEEEEE;
}

.nav-link.tab-title:hover {
	color: #E2620E;
}

.nav-link.tab-title.active {
	color: #E2620E;
}

/*Tabs*/


/*Links*/

.OrangeLink {
	color: #F18B21;
}
.OrangeLink:hover {
	color: #E2620E;
}

.BlueLink {
	color: #0076BE;
}

/*Typography*/

h1, h2, h3, h4, h5 {
	font-weight: 500;
}
`;

export const Footer = () => {
	const { t } = useTranslation();
    return (
        <Styles className="footer">
			 <footer className="bg-light p-4">
                <div className="container text-center">
                    <div className="row">
                        <div className="col">
                    <p className="mb-0">{new Date().getFullYear()} SunPower Corporation. All Rights Reserved.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <a href="https://us.sunpower.com/privacy"  target="_blank" rel="noreferrer" className="BlueLink">{t('privacy_policy')}</a>
                        </div>
                        <div className="col">
                        <a href="https://us.sunpower.com/terms"  target="_blank"  rel="noreferrer" className="BlueLink">{t('terms_of_use')}</a>
                        </div>
                    </div> 
                </div>
            </footer>
        </Styles>
    );
  
}

export default Footer;