import React, { useState, useCallback, useEffect, Fragment } from "react";
import gql from 'graphql-tag';
import 'bootstrap/dist/css/bootstrap.css';
import { useLazyQuery, useMutation } from "@apollo/client";
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Form, Input, Button, Checkbox, Select, Alert } from 'antd';
import { Container, Row, Col, Image } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay'
import NavigationBar from './NavigationBar';
import Header from './Header';
import Footer from './Footer';
import { useTranslation } from "react-i18next";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 24,
    },
};
const tailLayout = {
    wrapperCol: {
        span: 16,
    },
};
const { Option } = Select;
const Styles = styled.div`
.bg-SunPowerBlue {
	background-color: #0076be;
}
.payment-radio {
    margin-left: 150px;
}
.form-check-label {
    text-align: center important!;
}
.ant-radio-button {
    padding: 10px;
}
.OrangeBtn {
    margin-left: 400px;
	font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn:hover {
    
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2 {
    font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2:hover {
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.BlueBtn {
	font-weight: 500;
	background-color: #0076BE;
	color: #FFFFFF;
}

.BlueBtn:hover {
	background-color:#39607A;
	color: #FFFFFF;
}

.BlueBtn:active {
	background-color:#39607A;
	color: #FFFFFF;
}
.BlueBtn:focus {
	background-color:#39607A;
	color: #FFFFFF;
}


.BlueBtnOutline {
	font-weight: 500;
	border: 1px solid #0076BE;
    background-color:#FFFFFF !important;
    color: #0076BE;
}

.BlueBtnOutline:hover {
	background-color:#0076BE !important;
	color: #FFFFFF;
}

.BlueBtnOutline:focus {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueBtnOutline:active {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueRadioBtn:hover {
	border: 1px solid #0076BE;
	color: #0076BE;
}

.BlueRadioBtn.active {
	border: 1px solid #0076BE;
	color: #0076BE;
}

footer{
	height: 80px;
	width: 100%;
}
.errorMsg {
    color: red;
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.divider-light {
	background-color: #E0E0E5;
	height: 2px;
	margin-bottom: 14px;
}

.divider-orange {
	background-color: #F18B21;
	height: 3.5px;
	width: 36px;
}

.divider-orange-lg {
	background-color: #F18B21;
	height: 3px;
	width: 100px;
}


.bg-banner1 {
	background-image: url("images/1200x627_banner no logo (1).png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
}

.bg-SunPowerBlack {
	background-color: hsla(0,0%,0%,0.60);
	color:#FFFFFF;
	text-align: center;
}



/*Nav*/
.nav-link.active {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}

.nav-link:hover {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}
/*Nav*/

/*Tabs*/

.nav-link.tab-title {
	color: #858585;
	background-color: #EEEEEE;
}

.nav-link.tab-title:hover {
	color: #E2620E;
}

.nav-link.tab-title.active {
	color: #E2620E;
}

/*Tabs*/


/*Links*/

.OrangeLink {
	color: #F18B21;
}
.OrangeLink:hover {
	color: #E2620E;
}

.BlueLink {
	color: #0076BE;
}

/*Typography*/

h1, h2, h3, h4, h5 {
	font-weight: 500;
}
`;

// const mutation = gql`
//     mutation ContactUs($subject: String, $name: String, $email: String, $message: String) {
//         contactUs(subject:$subject, name: $name, email:$email, message:$message)
// }
// `;




const { TextArea } = Input;
const ContactUs = (props) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [paymentStatus, setPaymentStatus] = useState({ message: '' })
    const [showSpinner, setShowSpinner] = useState({ display: false })
    const [showTracker, setShowTracker] = useState(false)
    const [showSuccessMessage, setshowSuccessMessage] = useState(false);
    const [showErrorMessage, setshowErrorMessage] = useState(false);
    // const [insertItem] = useMutation(mutation);


    const SENDCONTACTDETAILS = gql`
            mutation contactUsReferral(
                $message:String!
                $subject:String!
                $First_and_Last_Name:String!
                $Email_Address:String!,
            ){
                contactUsReferral(
                  input: {
                      name: $First_and_Last_Name
                      subject:$subject
                      message: $message
                      email: $Email_Address
                  }
                ) {
                    checkSuccessMessage
                  }
              }
              `

    const [sendContactDetails] = useMutation(SENDCONTACTDETAILS);

    const onFinish = values => {
        setShowSpinner({ display: true });
        sendContactDetails({
            variables: {
                subject: values.subject,
                First_and_Last_Name: values.fullname,
                Email_Address: values.email,
                message: values.message,
            }
        })
            .then(
                res => {
                    if (res.data.contactUsReferral.checkSuccessMessage == true) {
                        form.resetFields();
                        setshowSuccessMessage(true);
                        setShowSpinner({ display: false });
                    }
                },
                err => {
                    setshowErrorMessage(true);
                    setShowSpinner({ display: false });
                    console.log(err);
                }
            );

    };

    return (
        <Fragment>
            <LoadingOverlay
                active={showSpinner.display}
                spinner
                text={t('loading_content')}
            >
                <NavigationBar/>
                <Container className="pb-4">
                    <Row className="text-white bg-SunPowerBlue">
                        <Col lg={6} className="p-5 align-self-center order-2 order-lg-1">
                            <h2 className="text-white text-uppercase">Customer Service</h2>
                            <h4 className="text-white">Have a question? Our customer service experts are here to help.</h4>
                        </Col>
                        <Col lg={6} className='px-0 order-1 order-lg-2'>
                            <Image fluid src="images/Header-Contact.png" className="h-100 w-100" />
                        </Col>

                    </Row>
                </Container>
                <Styles>
                    <main>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col">
                                    <p>{t('contact_by_phone')}: (844) 446-0893</p>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-md-6 border-end">
                                    <div className="mb-2">
                                        <Alert message={t('request_has_been_logged')} type="success" style={{ display: ((showSuccessMessage) ? 'block' : 'none') }} />
                                        <Alert message="Your request is not submitted . Please try again ." type="error" style={{ display: ((showErrorMessage) ? 'block' : 'none') }} />
                                    </div>
                                    <Form
                                        form={form}
                                        {...layout}
                                        onFinish={onFinish}
                                    >
                                        <Form.Item
                                            name="subject"
                                            rules={[{ required: true, message: t('subject') }]}
                                        >
                                            <Select placeholder={t('subject_placeholder')} virtual={false} >
                                                <Option value='SunPower Referral Inquiry'>{t('sunpower_payment_inquiry')}</Option>
                                                <Option value='My Referral Payment Status'>{t('my_payment_status')}</Option>
                                                <Option value='My Referral Visa Reward Card'>{t('visa_payment_reward_card')}</Option>
                                                <Option value='My SunPower Referral Check'>{t('my_sunpower_check')}</Option>
                                                <Option value='My Referral Virtual Visa Card'>{t('virtual_visa_payment')}</Option>
                                                <Option value='Update Information for my Referral'>{t('update_info_payment')}</Option>
                                                <Option value='SunPower Referral Email not Received'>{t('payment_email_not_received')}</Option>
                                                <Option value='SunPower Referral Payment Expired'>{t('payment_expired')}</Option>
                                                <Option value='Referral Payment Is Incorrect Amount'>{t('payment_incorrect_amt')}</Option>
                                                <Option value='Tracking Number for Referral Payment'>{t('trackingNumber_ref_payment')}</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="fullname"
                                            rules={[{ required: true, message: t('enter_your_full_name') }]}
                                        >
                                            <Input placeholder={t('track_name')} />
                                        </Form.Item>
                                        <Form.Item
                                            name="email"
                                            rules={[{ required: true, message: t('enter_your_email'), type: 'email' }]}
                                            type="email"

                                        >
                                            <Input placeholder={t('email')} />
                                        </Form.Item>
                                        <Form.Item
                                            name="message"
                                            rules={[{ required: true, message: t('enter_some_message') }]}
                                        >
                                            <TextArea placeholder={t('message')} rows={5} />
                                        </Form.Item>


                                        <Form.Item {...tailLayout}>
                                            <Button type="primary" htmlType="submit">
                                                {t('submit')}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                                <div className="col-md-4 d-flex align-items-start ms-3">
                                    <Col sm={12} className="ps-sm-7 order-1 order-sm-2">
                                        <h5>Contact by phone:</h5>
                                        <p className="mb-3">
                                            <a href="tel:844-446-0893">(844) 446-0893</a>
                                        </p>
                                        <h5>Hours Of Operation</h5>
                                        <p>  
                                             Mon - Fri | 8:00 AM - 4:00 PM PST
                                        </p>
                                        <h5>Cardholder Services Contact Information</h5>
                                        <p>
                                            <a href="tel:1-844-725-8818">1-844-725-8818</a>
                                        </p>
                                        <a href="https://bpsmyaccount.com/">bpsmyaccount.com</a>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </main>
                </Styles>
                <Footer />
            </LoadingOverlay>
        </Fragment>

    );
}

export default ContactUs;