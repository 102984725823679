import React from 'react';
import ReactLinkify from 'react-linkify';

const DisclaimerParagraph = ({ campaignDisclaimer }) => {
  return (
    <div>
      <p>
        {campaignDisclaimer &&
          campaignDisclaimer.map((campaign, index) => {
            return (
              <p key={index}>
                <ReactLinkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="_blank" rel="noopener noreferrer" href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                >
                  {campaign}
                </ReactLinkify>
              </p>
            );
          })
        }
      </p>
    </div>
  )
}

export default DisclaimerParagraph;
