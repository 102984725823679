import React, {Fragment} from 'react';
import NavigationBar from './NavigationBar';
import Header from './Header';
import CardBalanceContent from './CardbalanceContent';
import Footer from './Footer';
const MyVisaCard = () => {
    return (
        <Fragment>
            <NavigationBar/>
            <CardBalanceContent />
            <Footer />
        </Fragment>
    );
}
export default MyVisaCard;