import { useLazyQuery } from "@apollo/client";
import { Alert, Button, Col, Form, Spin } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { Container, Row } from "react-bootstrap";

import { Styles, commonFormProps } from "../../Constants";
import Footer from "../Footer";
import NavigationBar from "../NavigationBar";
import { Email, LastName, Phone } from "./formItems";
import { getReferralLink } from "./query";

export default function RetrieveLink() {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    getLink({
      variables: values,
    });
  };

  const [getLink, { data, loading, error }] = useLazyQuery(getReferralLink);

  return (
    <>
      <Styles>
        <NavigationBar />
        <Container className="p-4">
          <Row>
            <Col className="text-center py-4">
              <h1>Retrieve My Shareable Link</h1>
              <h5 className="text-secondary">
                Complete the form below to retrieve your link.
              </h5>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={8} md={4}>
              <Form form={form} onFinish={onFinish} {...commonFormProps}>
                <Row className="p-3 border rounded mb-3">
                  <LastName />
                  <Phone />
                  <Email />
                  {loading && <Spin />}
                  <div className="text-center">
                    <Button type="primary" htmlType="submit">
                      RETRIEVE MY SHAREABLE LINK
                    </Button>
                  </div>
                </Row>
              </Form>

              {!loading && data?.getReferrerShareableLink?.referrerLink && (
                <Row className="p-3 border rounded mb-3">
                  <p>Shareable Link: </p>
                  <p>
                    <a href={data.getReferrerShareableLink.referrerLink}>
                      {data.getReferrerShareableLink.referrerLink}
                    </a>
                  </p>
                </Row>
              )}
              {!loading && error && (
                <Row>
                  <Alert
                    message={error.message}
                    type="error"
                    className="mb-2"
                  ></Alert>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
        <Footer />
      </Styles>
    </>
  );
}
