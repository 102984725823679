import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';

const Styles = styled.div`
  .navbar {
        background-color: #fff;
    }

    a, .navbar-brand, .navbar-nav .nav-link {
        color: #6c757d;

        &:hover {
            color: hsla(0,0%,0%,1.00);
	        border-bottom: #F18B21 2px solid;
        }
        &:active {
            color: hsla(0,0%,0%,1.00);
	        border-bottom: #F18B21 2px solid;
        }
    }

    .nav-link.tab-title {
            color: #858585;
            background-color: #EEEEEE;
        }

        .nav-link.tab-title:hover {
            color: #E2620E;
        }

        .nav-link.tab-title.active {
            color: #E2620E;
        }
`;

const NavigationBar = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const [initialUrl, setInitialUrl] = useState("/track");

  useEffect(() => {
    if (location.pathname.includes("campaign")) {
      setInitialUrl("");
    } else if (location.pathname === "/") {
      setInitialUrl("");
    } else {
      setInitialUrl(localStorage.getItem("initialUrl"));
    }
  }, [location]);

  return (
    <Styles>
      <Navbar expand="lg">
        <Navbar.Brand as={Link} to={initialUrl}>
          <img src="/images/SunPower.png" width="180px" alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-center mx-auto">
            {/* <NavDropdown title="Campaign">
                <NavDropdown.Item>
                  <NavLink to="/">NEM Customer Comms 1</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <NavLink to="/campaign/SPD">Refer SunPower SPD</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <NavLink to="/campaign/ShareSunPower">
                    Share SunPower - Customer Referrals
                  </NavLink>
                </NavDropdown.Item>
              </NavDropdown> */}
            <Nav.Item >
              <Nav.Link as={Link} to="/retrievelink">Retrieve My Shareable Link</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/track">{t("track_payment_nav")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/contactus">{t("contact_us_nav")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/faqs">{t("faq_nav")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/cardbalance">{t("card_bal_nav")}</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Styles>
  );
};

export default NavigationBar;
