import styled from 'styled-components';

export const cities = [
  {
    name: 'Alabama',
    value: 'AL',
  },
  {
    name: 'Alaska',
    value: 'AK',
  },
  {
    name: 'Arizona',
    value: 'AZ',
  },
  {
    name: 'Arkansas',
    value: 'AR',
  },
  {
    name: 'California',
    value: 'CA',
  },
  {
    name: 'Colorado',
    value: 'CO',
  },
  {
    name: 'Connecticut',
    value: 'CT',
  },
  {
    name: 'Delaware',
    value: 'DE',
  },
  {
    name: 'District of Columbia',
    value: 'DC',
  },
  {
    name: 'Florida',
    value: 'FL',
  },
  {
    name: 'Georgia',
    value: 'GA',
  },
  {
    name: 'Hawaii',
    value: 'HI',
  },
  {
    name: 'Idaho',
    value: 'ID',
  },
  {
    name: 'Illinois',
    value: 'IL',
  },
  {
    name: 'Indiana',
    value: 'IN',
  },
  {
    name: 'Iowa',
    value: 'IA',
  },
  {
    name: 'Kansas',
    value: 'KS',
  },
  {
    name: 'Kentucky',
    value: 'KY',
  },
  {
    name: 'Louisiana',
    value: 'LA',
  },
  {
    name: 'Maine',
    value: 'ME',
  },
  {
    name: 'Maryland',
    value: 'MD',
  },
  {
    name: 'Massachusetts',
    value: 'MA',
  },
  {
    name: 'Michigan',
    value: 'MI',
  },
  {
    name: 'Minnesota',
    value: 'MN',
  },
  {
    name: 'Mississippi',
    value: 'MS',
  },
  {
    name: 'Missouri',
    value: 'MO',
  },
  {
    name: 'Montana',
    value: 'MT',
  },
  {
    name: 'Nebaraska',
    value: 'NE',
  },
  {
    name: 'Nevada',
    value: 'NV',
  },
  {
    name: 'New Hampshire',
    value: 'NH',
  },
  {
    name: 'New Jersey',
    value: 'NJ',
  },
  {
    name: 'New Mexico',
    value: 'NM',
  },
  {
    name: 'New York',
    value: 'NY',
  },
  {
    name: 'North Carolina',
    value: 'NC',
  },
  {
    name: 'North Dakota',
    value: 'ND',
  },
  {
    name: 'Ohio',
    value: 'OH',
  },
  {
    name: 'Oklahoma',
    value: 'OK',
  },
  {
    name: 'Oregon',
    value: 'OR',
  },
  {
    name: 'Pennsylvania',
    value: 'PA',
  },
  {
    name: 'Rhode Island',
    value: 'RI',
  },
  {
    name: 'South Carolina',
    value: 'SC',
  },
  {
    name: 'South Dakota',
    value: 'SD',
  },
  {
    name: 'Tennessee',
    value: 'TN',
  },
  {
    name: 'Texas',
    value: 'TX',
  },
  {
    name: 'Utah',
    value: 'UT',
  },
  {
    name: 'Vermont',
    value: 'VT',
  },
  {
    name: 'Virginia',
    value: 'VA',
  },
  {
    name: 'Washington',
    value: 'WA',
  },
  {
    name: 'West Virginia',
    value: 'WV',
  },
  {
    name: 'Wisconsin',
    value: 'WI',
  },
  {
    name: 'Wyoming',
    value: 'WY',
  },
];

export const patterns = {
  specialCharacterCheckPatternWithSpace: /^[a-z0-9\s_.-]+$/i,
  specialCharacterCheckPattern: /^[a-z0-9_.-]+$/i,
  phoneNumberCheckPattern: /^(?:\d*)$/,
  passwordCheck: /^(?=.*?[A-Z])(?=.*?[#?!@$%^&*-]).{8,}$/,
  zipCheck: /^[0-9]*$/,
  onlyChar: /^[a-zA-Z ]*$/g,
};

export const rewardTypes = {
  physical: 'visareward',
  virtual: 'virtualvisa',
  check: 'check',
};

export const commonFormProps = {
  requiredMark: false,
  layout: 'vertical',
};

export const Styles = styled.div`
  .bg-SunPowerBlue {
    background-color: #0076be;
  }
  .payment-radio {
    margin-left: 150px;
  }
  .form-check-label {
    text-align: center important!;
  }
  .ant-radio-button {
    padding: 10px;
  }
  .OrangeBtn {
    margin-left: 400px;
    font-weight: 500;
    background-color: #f18b21;
    color: hsla(0, 0%, 100%, 1);
  }
  .OrangeBtn:hover {
    background-color: #e2620e;
    color: hsla(0, 0%, 100%, 1);
  }
  .OrangeBtn2 {
    font-weight: 500;
    background-color: #f18b21;
    color: hsla(0, 0%, 100%, 1);
  }
  .OrangeBtn2:hover {
    background-color: #e2620e;
    color: hsla(0, 0%, 100%, 1);
  }
  .BlueBtn {
    font-weight: 500;
    background-color: #0076be;
    color: #ffffff;
  }

  .BlueBtn:hover {
    background-color: #39607a;
    color: #ffffff;
  }

  .BlueBtn:active {
    background-color: #39607a;
    color: #ffffff;
  }
  .BlueBtn:focus {
    background-color: #39607a;
    color: #ffffff;
  }

  .BlueBtnOutline {
    font-weight: 500;
    border: 1px solid #0076be;
    background-color: #ffffff !important;
    color: #0076be;
  }

  .BlueBtnOutline:hover {
    background-color: #0076be !important;
    color: #ffffff;
  }

  .BlueBtnOutline:focus {
    background-color: #39607a !important;
    color: #ffffff;
  }

  .BlueBtnOutline:active {
    background-color: #39607a !important;
    color: #ffffff;
  }

  .BlueRadioBtn:hover {
    border: 1px solid #0076be;
    color: #0076be;
  }

  .BlueRadioBtn.active {
    border: 1px solid #0076be;
    color: #0076be;
  }

  footer {
    height: 80px;

    width: 100%;
  }
  .errorMsg {
    color: red;
  }

  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }

  .divider-light {
    background-color: #e0e0e5;
    height: 2px;
    margin-bottom: 14px;
  }

  .divider-orange {
    background-color: #f18b21;
    height: 3.5px;
    width: 36px;
  }

  .divider-orange-lg {
    background-color: #f18b21;
    height: 3px;
    width: 100px;
  }

  .bg-banner1 {
    background-image: url('images/1200x627_banner no logo (1).png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .bg-SunPowerBlack {
    background-color: hsla(0, 0%, 0%, 0.6);
    color: #ffffff;
    text-align: center;
  }

  /*Nav*/
  .nav-link.active {
    color: #f18b21;
    border-bottom: #f18b21 2px solid;
  }

  .nav-link:hover {
    color: #f18b21;
    border-bottom: #f18b21 2px solid;
  }
  /*Nav*/

  /*Tabs*/

  .nav-link.tab-title {
    color: #858585;
    background-color: #eeeeee;
  }

  .nav-link.tab-title:hover {
    color: #e2620e;
  }

  .nav-link.tab-title.active {
    color: #e2620e;
  }

  /*Links*/

  .OrangeLink {
    color: #f18b21;
  }
  .OrangeLink:hover {
    color: #e2620e;
  }

  .BlueLink {
    color: #0076be;
  }

  /*Typography*/

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 500;
  }
`;
