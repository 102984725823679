import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Button, Modal, Form, Input, Select, Checkbox } from 'antd';
import { Container,  Row,Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const { Option } = Select;
const MailingModal = ({ visible, onCreate, onCancel, displayCheckBox, mailingAddress }) => {
    const { t } = useTranslation();
  const [form] = Form.useForm();
  const [showOther, setShowOther] = useState(false);
  const [validateOther, setValidateOther] = useState(false);
  const renderCheckBox = (displayCheckBox) => {
    if(displayCheckBox){
      return <Row><Col md={8}><Checkbox name='stillOwn'>{t('still_own_this_home')}</Checkbox></Col></Row>;
    } 
  }

  const handleAddressChange = (value) => {
      if (value == 'Other') {
        setShowOther(true);
        setValidateOther(true);
      } else {
        setShowOther(false);
        setValidateOther(false);
      }
  }
  
  return (
    <Modal
      visible={visible}
      title={t('edit_mailing_addr')}
      okText={t('save_changes')}
      cancelText={t('cancel')}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onCreate(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
    <Container>
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
            modifier: 'public',
            address1: mailingAddress.address1,
            address2: mailingAddress.addess2,
            city: mailingAddress.city,
            state: mailingAddress.state,
            zip: mailingAddress.zip
            }}
        >
            <Row>
                <Col md={8}>
                    <Form.Item
                        name="address1"
                        rules={[
                            {
                            required: true,
                            message: t('please_input_address1'),
                            },
                        ]}
                        >
                        <Input placeholder={t('address_1')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <Form.Item name="address2" >
                        <Input placeholder={t('address_2')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Form.Item
                        name="city"
                        placeholder={t('city')}
                        rules={[
                            {
                            required: true,
                            message: t('please_input_city'),
                            },
                        ]}
                        >
                        <Input placeholder={t('city')} />
                    </Form.Item>
                </Col>
                <Col md={4}>
                    <Form.Item
                        name="state"
                        rules={[
                            {
                            required: true,
                            message: t('please_select_state'),
                            },
                        ]}
                        >
                        <Select placeholder={t('state')}>
                            <Option value='AL'>AL</Option>
                            <Option value='AK'>AK</Option>
                            <Option value='AZ'>AZ</Option>
                            <Option value='AR'>AR</Option>
                            <Option value='CA'>CA</Option>
                            <Option value='CO'>CO</Option>
                            <Option value='CT'>CT</Option>
                            <Option value='DE'>DE</Option>
                            <Option value='FL'>FL</Option>
                            <Option value='GA'>GA</Option>
                            <Option value='HI'>HI</Option>
                            <Option value='ID'>ID</Option>
                            <Option value='IL'>IL</Option>
                            <Option value='IN'>IN</Option>
                            <Option value='IA'>IA</Option>
                            <Option value='KS'>KS</Option>
                            <Option value='KY'>KY</Option>
                            <Option value='LA'>LA</Option>
                            <Option value='ME'>ME</Option>
                            <Option value='MD'>MD</Option>
                            <Option value='MA'>MA</Option>
                            <Option value='MI'>MI</Option>
                            <Option value='MN'>MN</Option>
                            <Option value='MS'>MS</Option>
                            <Option value='MO'>MO</Option>
                            <Option value='MT'>MT</Option>
                            <Option value='NE'>NE</Option>
                            <Option value='NV'>NV</Option>
                            <Option value='NH'>NH</Option>
                            <Option value='NJ'>NJ</Option>
                            <Option value='NM'>NM</Option>
                            <Option value='NY'>NY</Option>
                            <Option value='NC'>NC</Option>
                            <Option value='ND'>ND</Option>
                            <Option value='OH'>OH</Option>
                            <Option value='OK'>OK</Option>
                            <Option value='OR'>OR</Option>
                            <Option value='PA'>PA</Option>
                            <Option value='RI'>RI</Option>
                            <Option value='SC'>SC</Option>
                            <Option value='SD'>SD</Option>
                            <Option value='TN'>TN</Option>
                            <Option value='TX'>TX</Option>
                            <Option value='UT'>UT</Option>
                            <Option value='VT'>VT</Option>
                            <Option value='VA'>VA</Option>
                            <Option value='WA'>WA</Option>
                            <Option value='WV'>WV</Option>
                            <Option value='WI'>WI</Option>
                            <Option value='WY'>WY</Option>	
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={4}>
                    <Form.Item
                        name="zip"
                        
                        rules={[
                            {
                            required: true,
                            message: t('please_input_zip'),
                            },
                        ]}
                        >
                        <Input placeholder={t('zip')} />
                    </Form.Item>
                </Col>
            </Row>
            {renderCheckBox(displayCheckBox)}
            <Row>
                <Col md={8}>
                    <Form.Item
                        name="addrChangeReason"
                        rules={[
                            {
                            required: true,
                            message: t('please_Select_addr_change_reason'),
                            },
                        ]}
                        >
                        <Select placeholder={t('why_change_addr')} onChange={(e) => handleAddressChange(e)}>
                            <Option value='Temporarily Away'>{t('temp_away')}</Option>
                            <Option value='Change PO Box Address'>{t('change_po_addr')}</Option>
                            <Option value='We have moved'>{t('we_have_moved')}</Option>
                            <Option value='Other'>{t('other')}</Option>	
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {showOther ?
                <Row>
                    <Col md={8}>
                        <Form.Item name="otherReason" 
                            rules={[
                                {
                                required: validateOther,
                                message: t('please_select_other_reason'),
                                },
                            ]}
                        >
                            <Input placeholder={t('reason_addr_change')} />
                        </Form.Item>
                    </Col>
                </Row>
            : null}
        </Form>
      </Container>
    </Modal>
  );
};

export default MailingModal;