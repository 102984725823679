import { Form, Input } from 'antd'
import React from 'react'

const SSNLABEL = () => {
  return (
    <div className='mt-4'>
      <div>Social Security Number (SSN)</div>
      <div className='m-0 p-0'>SunPower is asking for your Social Security Number or Individual Taxpayer Identification Number to complete a 1099-K Form. If you have reached $600 in referral payments for a calendar year you will receive a 1099-K Form by February the following year.</div>
    </div>
  )
}

const SSN = ({ disabled }) => {

  const checkSameNumber = async (_, ssn) => {
    const cleanedSSN = ssn.replace(/-/g, '');
    // Convert the SSN to an array of digits
    const digitsArray = Array.from(cleanedSSN).map(Number);

    // Check if all digits are the same
    const allDigitsSame = digitsArray.every((digit, index, array) => digit === array[0]);

    if (allDigitsSame) {
      return Promise.reject()
    }
    return Promise.resolve()
  }

  return (
    <Form.Item
      name="ssn"
      label={<SSNLABEL />}
      rules={[
        {
          required: true,
          message: "This field is mandatory to complete the request",
        },
        {
          pattern: /^[^A-Za-z!@#$%^&*()_+=\[\]{}|;:'",.<>/?]*$/,
          message: "No letters or special characters are allowed."
        },
        {
          pattern: /^\d{3}-\d{2}-\d{4}$|^\d{9}$/,
          message: "The format for SSN should be either XXX-XX-XXXX or XXXXXXXXX"
        },
        {
          pattern: /^(?!000|666|9\d{2}|123[- ]?45[- ]?6789)\d{3}[- ]?\d{2}[- ]?\d{4}$/,
          message: "The SSN should not be listed in consecutive format."
        },
        { validator: checkSameNumber, message: "This SSN is not a valid. The same number cannot be used for the whole SSN." },
        {
          pattern: /^(?!(000|666|9|123456789))(\d{3}-\d{2}-\d{4})$|^(?!(000|666|9|123456789))\d{9}$/,
          message: "The Social Security Number entered is invalid.",
        },

      ]}
      validateFirst={true}
    >
      <Input disabled={disabled} />
    </Form.Item>
  )
}

export default SSN

export { SSN }
