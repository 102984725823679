import { Form, Input, Select } from 'antd'
import { cities } from '../../Constants'

function FirstName() {
	return (
		<Form.Item
			name="fname"
			label="First Name"
			rules={[
				{
					required: true,
					whitespace: true,
					message: 'Please enter your first name',
				},
			]}
		>
			<Input disabled />
		</Form.Item>
	)
}

function LastName() {
	return (
		<Form.Item
			name="lname"
			label="Last Name"
			rules={[
				{
					required: true,
					whitespace: true,
					message: 'Please enter your last name',
				},
			]}
		>
			<Input disabled />
		</Form.Item>
	)
}

function Email() {
	return (
		<Form.Item
			name="email"
			label="Email"
			rules={[
				{ type: 'email', message: 'Please enter a valid email address' },
				{
					required: true,
					message: 'Please enter an email address',
				},
			]}
		>
			<Input disabled />
		</Form.Item>
	)
}

function Phone() {
	return (
		<Form.Item
			name="phone"
			label="Phone"
			rules={[
				{
					required: true,
					message: 'Please enter your phone number',
				},
				{
					pattern: /^[1-9][0-9]{9}$/,
					message: 'Phone number must be 10 digits',
				},
			]}
		>
			<Input disabled />
		</Form.Item>
	)
}

function Address1() {
	return (
		<Form.Item
			name="address1"
			label="Address 1"
			rules={[
				{
					required: true,
					whitespace: true,
					message: 'Please enter your address',
				},
			]}
		>
			<Input />
		</Form.Item>
	)
}

function Address2() {
	return (
		<Form.Item
			name="address2"
			label="Address 2"
			rules={[
				{
					whitespace: true,
					message: 'Please enter your address',
				},
			]}
		>
			<Input />
		</Form.Item>
	)
}

function City() {
	return (
		<Form.Item
			name="city"
			label="City"
			rules={[
				{
					required: true,
					whitespace: true,
					message: 'Please enter a city',
				},
			]}
		>
			<Input />
		</Form.Item>
	)
}

const State = ({ disabled }) => {
	return (
		<Form.Item
			name="state"
			label="State"
			rules={[
				{
					required: true,
					message: 'Please select a state',
				},
			]}
		>
			<Select virtual={false} options={cities} disabled={disabled} />
		</Form.Item>
	)
}

function Zip() {
	return (
		<Form.Item
			name="zip"
			label="ZIP"
			rules={[
				{
					required: true,
					message: 'Please enter your ZIP code',
				},
				{
					pattern: /^[0-9]{5}$/,
					message: 'ZIP code must be 5 digits',
				},
			]}
		>
			<Input />
		</Form.Item>
	)
}

export {
	FirstName,
	LastName,
	Email,
	Phone,
	Address1,
	Address2,
	City,
	State,
	Zip,
}
