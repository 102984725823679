import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import NavigationBar from './NavigationBar';
import Footer from './Footer';
import 'antd/dist/antd.css';
import './PeguPayments-style.css';
import { useLocation } from 'react-router-dom';
import { Alert } from 'antd';
import { useState } from 'react';




const ConfirmationForm = ( props ) => {

  const location = useLocation();

  const [initialUrl] = useState(localStorage.getItem("initialUrl") || "");


  return (
    <Container className="px-0 overflow-hidden h-100 ">
      <NavigationBar/>
      <Container className="py-4 text-center" style={{ marginTop: "10%" }}>

        <Row>
          <Col>
            <h1 className='mb-2'>Success! Your referral has been received.</h1>

            <p className='mb-0'>
              Once the person you referred has completed their SunPower solar
              install your $500 Visa Rewards referral payment will be processed.
            </p>
            
            <p>
              You can track the status of your referral any time at{" "
              }
              <a href="https://sunpowerreferralpayments.com/track" className='BlueLink'>SunPowerReferralPayments.com</a>
            </p>
            <p className='mb-4'>Thank you for choosing and recommending SunPower!</p>
            <hr  className='w-50 mx-auto'/>
            <p className='mt-4'>
              Have more friends who might be interested in SunPower solar? 
            </p>
            <a href={ initialUrl } className='btn OrangeBtn'>Enter another referral here!</a>
            
          </Col>
        </Row>

      </Container>
      <Container fluid className="pt-5 fixed-bottom">
        <Footer />
      </Container>
    </Container>
  );
}
export default ConfirmationForm;
