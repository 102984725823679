import { gql } from "@apollo/client";

const getReferralLink = gql`
  query GetReferrerShareableLink(
    $lastName: String!
    $phone: String!
    $email: String!
  ) {
    getReferrerShareableLink(
      lastName: $lastName
      phone: $phone
      email: $email
    ) {
      referrerLink
    }
  }
`;

export {getReferralLink}