import React, { useState, Fragment, useEffect } from "react";
import styled from "styled-components";
import queryString from "query-string";
import { gql, useMutation } from "@apollo/client";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import Footer from "./Footer";
import "../styles.css";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { getBpsAccountUrl } from "../HelperFunctions/General"

const Styles = styled.div`
  .bg-SunPowerBlue {
    background-color: #0076be;
  }
  .payment-radio {
    margin-left: 150px;
  }
  .form-check-label {
    text-align: center important!;
  }
  .ant-radio-button {
    padding: 10px;
  }
  .OrangeBtn {
    margin-left: 400px;
    font-weight: 500;
    background-color: #f18b21;
    color: hsla(0, 0%, 100%, 1);
  }
  .OrangeBtn:hover {
    background-color: #e2620e;
    color: hsla(0, 0%, 100%, 1);
  }
  .BlueBtn {
    font-weight: 500;
    background-color: #0076be;
    color: #ffffff;
  }

  .BlueBtn:hover {
    background-color: #39607a;
    color: #ffffff;
  }

  .BlueBtn:active {
    background-color: #39607a;
    color: #ffffff;
  }
  .BlueBtn:focus {
    background-color: #39607a;
    color: #ffffff;
  }
  .left-align {
    margin-left: -100px;
  }

  .BlueBtnOutline {
    font-weight: 500;
    border: 1px solid #0076be;
    background-color: #ffffff !important;
    color: #0076be;
  }

  .BlueBtnOutline:hover {
    background-color: #0076be !important;
    color: #ffffff;
  }

  .BlueBtnOutline:focus {
    background-color: #39607a !important;
    color: #ffffff;
  }

  .BlueBtnOutline:active {
    background-color: #39607a !important;
    color: #ffffff;
  }

  .BlueRadioBtn:hover {
    border: 1px solid #0076be;
    color: #0076be;
  }

  .BlueRadioBtn.active {
    border: 1px solid #0076be;
    color: #0076be;
  }

  main {
    min-height: 100vh;
    padding-bottom: 90px;
  }

  footer {
    height: 80px;
    margin-top: -80px;
    width: 100%;
  }

  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }

  .divider-light {
    background-color: #e0e0e5;
    height: 2px;
    margin-bottom: 14px;
  }

  .divider-orange {
    background-color: #f18b21;
    height: 3.5px;
    width: 36px;
  }

  .divider-orange-lg {
    background-color: #f18b21;
    height: 3px;
    width: 100px;
  }

  .bg-banner1 {
    background-image: url("images/1200x627_banner no logo (1).png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .bg-SunPowerBlack {
    background-color: hsla(0, 0%, 0%, 0.6);
    color: #ffffff;
    text-align: center;
  }

  /*Nav*/
  .nav-link.active {
    color: #f18b21;
    border-bottom: #f18b21 2px solid;
  }

  .nav-link:hover {
    color: #f18b21;
    border-bottom: #f18b21 2px solid;
  }
  /*Nav*/

  /*Tabs*/

  .nav-link.tab-title {
    color: #858585;
    background-color: #eeeeee;
  }

  .nav-link.tab-title:hover {
    color: #e2620e;
  }

  .nav-link.tab-title.active {
    color: #e2620e;
  }

  /*Tabs*/

  /*Links*/

  .OrangeLink {
    color: #f18b21;
  }
  .OrangeLink:hover {
    color: #e2620e;
  }

  .BlueLink {
    color: #0076be;
  }

  /*Typography*/

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 500;
  }
`;

/*const REDEEM_MUTATION = `
    mutation VirtualCardRedeem($token : String!) {
        virtualCardRedeem(token: $token) {
            redeemUrl
            redeemToken
        }
    }
`;*/
const REDEEM_MUTATION = (token) => `
  mutation {
    virtualCardRedeem(token: "${token}") {
        redeemUrl
        redeemToken
        customErrorMessage
    }
}
`;
const axiosGraphQL = axios.create({
  baseURL: process.env.REACT_APP_SYSTEM_URL,
});

const RedeemVirtual = (props) => {
  const values = queryString.parse(window.location.search);
  const token = values.token;
  const [showSpinner, setShowSpinner] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [customMessage, setShowCustomMessage] = useState();
  const mqSiteUrl = process.env.REACT_APP_MQ_URL;

  //const [redeemToken] = useMutation(REDEEM_MUTATION)

  // Commented as now we are redirecting to MQ.
  // useEffect(() => {
  //   /*redeemToken({
  //           variables: { token }
  //           }).then(
  //           res => {
  //               console.log('res: ',res);

  //           },
  //           err => {
  //               console.log(err); 
  //           }
  //           );*/
  //   try {
  //     axiosGraphQL
  //       .post("", {
  //         query: REDEEM_MUTATION(token),
  //       })
  //       .then((result) => {
  //         console.log(result.data.data.virtualCardRedeem);
  //         if (result.data.data.virtualCardRedeem.redeemToken === "queued") {
  //           setShowMessage(true);
  //           setShowSpinner(false);
  //         } else if (
  //           result.data.data.virtualCardRedeem.redeemToken === "error"
  //         ) {
  //           setShowCustomMessage(
  //             result.data.data.virtualCardRedeem.customErrorMessage
  //           );
  //           setShowSpinner(false);
  //         } else {
  //           window.location = result.data.data.virtualCardRedeem.redeemUrl;
  //         }
  //       });
  //   } catch (err) {
  //     console.log(err);
  //     console.log("failed");
  //   }
  // }, []);

  useEffect(() => {
    if (mqSiteUrl) {
      // Redirect to the specified URL if REACT_APP_MQ_URL is present
      setShowSpinner(true)
      window.location.replace(getBpsAccountUrl(token, "virtualvisa"))
    }
  }, [mqSiteUrl, token])

  return (
    <>
      <LoadingOverlay
        styles={{
          wrapper: {
            minHeight: "100vh",
          },
        }}
        active={showSpinner}
        spinner
        text={"Redirecting to CardAccount site..."}
      >
        <Styles>
          <Container fluid className="p-4 ">
            <Row>
              <Col md={2}>
                <Image fluid src="images/SunPower.png" className="nav-logo" />
              </Col>
            </Row>
          </Container>
          <Container fluid className="pt-5">
            <div className="text-center">
              <div className="row justify-content-center">
                <div className="col-10 p-5">
                  <p style={{ display: showMessage ? "block" : "none" }}>
                    This request is taking longer than expected. You will
                    receive an email with your virtual card in the next 2-3
                    hours.
                  </p>
                  <p style={{ display: showMessage ? "block" : "none" }}>
                    We apologize for the inconvenience
                  </p>
                  <p style={{ display: customMessage ? "block" : "none" }}>
                    <>
                      {customMessage && customMessage.includes("Contact Customer Service") ? (
                        <span>
                          This payment can no longer be redeemed. If you have
                          any questions you can{" "}
                          <a href="/contactus">Contact Customer Service</a>
                        </span>
                      ) : (
                        customMessage
                      )}
                    </>
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </Styles>
      </LoadingOverlay>
      <Footer />
    </>
  );
};

export default RedeemVirtual;
